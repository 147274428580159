<template lang="pug">
.set-content
  dialogMask(:show.sync="showDialog", :showClose="true")
    .title
      img(src="../../../assets/progress.png" alt="")
      .title-desc 项目进度
    .content-add
      .project-progress(v-for="(item, index) in list")
        img.logo-pic(:src="item.logoPicUrl" @click="handelToDeatil(item)")
        .progerss-desc
          .name(@click="handelToDeatil(item)")
            span.org-name {{item.orgName}}
            span.submitter-type {{item.submitterType}}
            span.time {{item.createTime}}
          .desc {{item.description}}
        img.del(src="./../../../assets/delete.png" v-if="status !== '已停止' && (item.hasDeleteAuth)" @click="del(item.id)")
        //- 
</template>

<script>
import dialogMask from '@/components/common/dialog.vue'
import {delProcess} from '@/api/project'

export default {
  name: 'memberProgressAll',
  props: ['showProjectProgressAll', 'list', 'projectId', 'status'],
  components: {
    dialogMask
  },
  data() {
    return {
      showDialog: false,
      progressList: [],
      form: {
        nickName: '',
        projectDirector: false
      }
    }
  },
  watch: {
    showProjectProgressAll(val) {
      if (val) this.showDialog = true
    },
    showDialog(val) {
      if (!val) {
        this.$emit('update:showProjectProgressAll', false)
      }
    }
  },
  methods: {
    handelToDeatil(item) {
      if (item.submitterType === '公司') {
        this.$router.push({
          path: `/companyDetail/${item.orgId}`
        })
      } else {
        this.$router.push({
          path: `/teams/detail/${item.orgId}`
        })
      }
    },
    del(id) {
      delProcess({
        projectId: this.projectId,
        id: id
      }).then(res => {
        if (res.code === 200) {
          this.$message.success('删除成功')
          this.$emit('updateProgress')
        } else {
          this.$message.error(res.msg)
        }
      })
      console.log('id ', id)
    }
  }
}
</script>

<style lang="scss" scoped>
.set-content {
  user-select: none;
  .title {
    padding-left: 20px;
    margin-bottom: 40px;
    img {
      width: 34px;
      height: 34px;
      margin-right: 10px;
      display: inline-block;
      font-size: 0;
    }
    .title-desc {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 34px;
      display: inline-block;
      vertical-align: top;
    }
  }
  .add {
    width: 446px;
    height: 46px;
    line-height: 46px;
    background: #5ad3cd;
    border-radius: 23px;
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
    margin: 0 0 0 200px;
  }
  .project-progress {
    overflow: hidden;
    margin-bottom: 40px;
    .logo-pic {
      width: 80px;
      height: 80px;
      border-radius: 2px;
      float: left;
      margin-right: 20px;
      margin-left: 70px;
      cursor: pointer;
    }
    .progerss-desc {
      width: 463px;
      float: left;
      .name {
        cursor: pointer;
        margin-bottom: 10px;
        margin-top: 10px;
        .org-name {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 22px;
          display: inline-block;
          margin-right: 5px;
        }
        .submitter-type {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          margin-right: 63px;
        }
        .time {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 22px;
        }
        .member-desc {
          width: 320px;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 26px;
          display: inline-block;
        }
      }
    }
    .desc {
      width: 463px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
    }
    .del,
    .member-del {
      float: right;
      width: 20px;
      height: 20px;
      margin-top: 58px;
      margin-right: 70px;
      cursor: pointer;
    }
    .member-del {
      margin-top: 10px;
    }
  }
}
</style>
