<template lang="pug">
.set-content
  dialogMask(:show.sync="showDialog", :showClose="true")
    .title
      img(src="../../../assets/progress.png" alt="")
      .title-desc 成员进度
    .content-add
      .project-progress(v-for="(item, index) in list")
        img.author-pic(:src="item.picUrl" @click="gotoCompanyDetailPage(item.employeeId)")
        .progerss-desc(@click="gotoCompanyDetailPage(item.employeeId)")
          .name 
            span.nick-name {{item.nickName}}
            span.add-time {{item.createTime}}
            .member-desc {{item.description}}
        img.member-del(@click="del(item.id)" src="./../../../assets/delete.png" v-if="status !== '已停止' && (jurisdictionCode.includes(1) || item.hasDeleteAuth)")
</template>

<script>
import dialogMask from '@/components/common/dialog.vue'
import {delMemberProgress} from '@/api/project'

export default {
  name: 'memberProgressAll',
  props: ['showMemberProgressAll', 'list', 'jurisdictionCode', 'status', 'projectId'],
  components: {
    dialogMask
  },
  data() {
    return {
      showDialog: false,
      progressList: [],
      form: {
        nickName: '',
        projectDirector: false
      }
    }
  },
  watch: {
    showMemberProgressAll(val) {
      if (val) this.showDialog = true
    },
    showDialog(val) {
      if (!val) {
        this.$emit('update:showMemberProgressAll', false)
      }
    }
  },
  methods: {
    del(id) {
      delMemberProgress({
        projectId: this.projectId,
        id: id
      }).then(res => {
        if (res.code === 200) {
          this.$message.success('删除成功')
          this.$emit('updateProcessMemberList')
        } else {
          this.$message.error(res.msg)
        }
      })
      console.log('id ', id)
    },
    gotoCompanyDetailPage(id) {
      this.$router.push({
        path: `/personal/detail/${id}`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.set-content {
  user-select: none;
  .title {
    padding-left: 20px;
    margin-bottom: 40px;
    img {
      width: 34px;
      height: 34px;
      margin-right: 10px;
      display: inline-block;
      font-size: 0;
    }
    .title-desc {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 34px;
      display: inline-block;
      vertical-align: top;
    }
  }
  .add {
    width: 446px;
    height: 46px;
    line-height: 46px;
    background: #5ad3cd;
    border-radius: 23px;
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
    margin: 0 0 0 200px;
  }
  .project-progress {
    overflow: hidden;
    margin-bottom: 46px;
    .author-pic {
      width: 46px;
      height: 46px;
      float: left;
      border-radius: 50%;
      margin-right: 25px;
      margin-left: 70px;
    }
    .logo-pic {
      width: 40px;
      height: 30px;
      border-radius: 2px;
      float: left;
      margin-right: 20px;
      margin-left: 70px;
    }
    .progerss-desc {
      float: left;
      .name {
        float: left;
        margin-bottom: 20px;
        margin-top: 10px;
        .nick-name {
          width: 36px;
          height: 25px;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 25px;
          margin-right: 30px;
          vertical-align: top;
        }
        .add-time {
          width: 160px;
          height: 26px;
          background: #f6f6f6;
          border-radius: 2px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 26px;
          text-align: center;
          margin-right: 27px;
          vertical-align: top;
          overflow: hidden;
          display: inline-block;
        }
        .org-name {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 22px;
          display: inline-block;
          margin-right: 5px;
        }
        .submitter-type {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          margin-right: 63px;
        }
        .time {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 22px;
        }
        .member-desc {
          width: 300px;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 26px;
          display: inline-block;
        }
        .desc {
          width: 922px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 22px;
        }
      }
    }
    .del,
    .member-del {
      float: right;
      width: 20px;
      height: 20px;
      margin-top: 58px;
      margin-right: 70px;
      cursor: pointer;
    }
    .member-del {
      margin-top: 10px;
    }
  }
}
</style>
