<template lang="pug">
  .publish-content
    dialogMask(:show.sync="dialogVisible", :showClose="true")
      .title
        img(src="./../../assets/aircraft.png")
        p {{ type === 'change' ? '修改项目' : '发布项目'}}
      .content
        .options
          .name.required 项目名称
          .item
            el-input(v-model="form.projectName")
        .options
          .name.required 项目类型
          .item
            el-select(v-model="form.businessType" placeholder="请选择")
              el-option(
                v-for="item in businessList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              )
        .options
          .name 项目预算
          .item
            el-input.budget(v-model="form.projectBudget")
            el-select.budget-unit(v-model="form.projectBudgetUnit" placeholder="请选择")
              el-option(label="元" value="元")
              el-option(label="万" value="万")
        .options
          .name.required 合作方式
          .item
            el-select(v-model="form.cooperationWay" placeholder="请选择")
              el-option(
                v-for="item in teamworkList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              )
        .options
          .name.required 委托环节
          .item
            el-select(v-model="entrustPart" placeholder="请选择")
              el-option(label="整体委托" value="整体委托")
              el-option(label="部分委托" value="部分委托")
            .tags(v-show="entrustPart === '部分委托'")
              .tag(v-for="(item, index) in tagList", :class="[item.select ? 'select-tag' : '']", v-if="index !== 0" @click="setTag(index)") {{item.name}}
        .options
          .name.required 项目简介
          .item
            el-input(v-model="form.introduction" type="textarea")
        .options
          .name 平台费用
          .item
            .platform_fees {{projectConfigInfo}}
        .options
          .name.required 公司名称
          .item
            el-input(v-model="form.customerName")
        .options
          .name.required 联系手机
          .item
            el-input(v-model="form.contactPhoneNumber")
      .submit(@click="submit") {{ type === 'change' ? '修改' : '发布'}}

</template>

<script>
import dialogMask from '@/components/common/dialog.vue'
import {publishProject, changeProjectInfo, projectGetConfigInfo} from '@/api/project'
import {verifyPhone} from '@/utils/index'
export default {
  name: 'publishDialog',
  props: ['showPublish', 'partList', 'teamworkList', 'businessList', 'projectInfo', 'projectId', 'type'],
  mounted(){
    projectGetConfigInfo().then(res=>{
      this.projectConfigInfo = res.data ||'成交额的1%，服务方承担'
    })
  },
  data() {
    return {
      dialogVisible: false,
      form: {
        businessType: 1,
        contactPhoneNumber: '',
        cooperationWay: 1,
        customerName: '',
        entrustPart: [''],
        introduction: '',
        projectBudget: '',
        projectBudgetUnit: '元',
        projectId: '',
        projectName: ''
      },
      entrustPart: '整体委托',
      tagList: [],
      projectConfigInfo:'',
    }
  },
  watch: {
    'form.projectBudget'(val) {
      let projectBudget = 0
      if (val < 0) {
        this.$set(this.form, 'projectBudget', projectBudget)
        return
      }
      if (val > 10000 && this.form.projectBudgetUnit !== '万') {
        projectBudget = val / 10000
        this.$set(this.form, 'projectBudget', projectBudget)
        this.$set(this.form, 'projectBudgetUnit', '万')
      }
    },
    projectInfo() {
      const projectInfo = JSON.parse(JSON.stringify(this.projectInfo))
      if (projectInfo) {
        this.form = this.setInitInfo(projectInfo)
        if (this.partList) this.setPatList(this.partList)
      }
    },
    showPublish(val) {
      if (val) this.dialogVisible = val
    },
    dialogVisible(val) {
      if (!val) {
        this.$emit('update:showPublish', false)
        if (!this.projectId) this.reset()
      }
    },
    partList(val) {
      this.setPatList(val)
    },
    entrustPart(val) {
      if (val === '整体委托') {
        this.tagList.forEach(item => {
          item.select = false
        })
        if (this.tagList[0]) {
          this.tagList[0].select = true
        }
      } else {
        if (this.tagList[0]) {
          this.tagList[0].select = false
        }
      }
    }
  },
  components: {
    dialogMask
  },
  methods: {
    reset() {
      this.form = {
        businessType: 1,
        contactPhoneNumber: '',
        cooperationWay: 1,
        customerName: '',
        entrustPart: ['1'],
        introduction: '',
        projectBudget: '',
        projectBudgetUnit: '元',
        projectId: '',
        projectName: ''
      }
      this.entrustPart = '整体委托'
    },
    setPatList(val) {
      const tagList = []
      val.forEach(item => {
        if (this.form.entrustPart.includes(item.id.toString())) {
          item.select = true
        } else {
          item.select = false
        }
        tagList.push(item)
      })
      this.tagList = tagList
    },
    setInitInfo(info) {
      let projectBudgetUnit = '元'
      if (info.budget >= 10000) {
        info.budget = info.budget / 10000
        projectBudgetUnit = '万'
      }
      this.entrustPart = info.entrustPart && info.entrustPart[0] === '1' ? '整体委托' : '部分委托'
      return {
        businessType: info.businessType,
        contactPhoneNumber: info.contactPhoneNumber,
        cooperationWay: info.cooperationWay,
        customerName: info.decryptCustomerName,
        entrustPart: info.entrustPart,
        introduction: info.introduction,
        projectBudget: info.budget,
        projectBudgetUnit: projectBudgetUnit,
        projectId: this.projectId,
        projectName: info.name
      }
    },
    setTag(index) {
      const tag = JSON.parse(JSON.stringify(this.tagList[index]))
      tag.select = !tag.select
      this.$set(this.tagList, index, tag)
      console.log(this.tagList)
    },
    verify() {
      let message = ''
      const {
        businessType,
        contactPhoneNumber,
        cooperationWay,
        customerName,
        introduction,
        projectBudget,
        projectName
      } = this.form

      if (introduction.length > 2000) {
        message = '项目详情最大长度为 2000 个字符'
      }
      if (introduction.length === 0) {
        message = '请输入项目简介'
      }
      if (!cooperationWay) {
        message = '请选择合作方式'
      }
      if (projectBudget === '') {
        this.form.projectBudgetUnit = '元'
      }
      if (!customerName) {
        message = '请填写公司名称'
      }
      if (customerName.length > 19) {
        message = '公司名称要限制在20字以内'
      }
      if (projectName.length > 20) {
        message = '项目名称最大长度为 20 个字符'
      }
      if (projectName.length === 0) {
        message = '请输入项目名称'
      }

      if (!businessType) {
        message = '请选择项目类型'
      }

      if (!verifyPhone(contactPhoneNumber)) return false
      if (message) {
        this.$message.error(message)
        return false
      }
      return true
    },
    submit() {
      const {
        businessType,
        contactPhoneNumber,
        cooperationWay,
        customerName,
        introduction,
        projectBudget,
        projectBudgetUnit,
        // projectId,
        projectName
      } = this.form
      if (!this.verify()) return
      let entrustPart = []
      if (this.entrustPart === '整体委托') {
        entrustPart = [1]
      } else {
        this.tagList.forEach(item => {
          if (item.select) {
            entrustPart.push(item.id)
          }
        })
      }
      const params = {
        businessType,
        contactPhoneNumber,
        cooperationWay,
        customerName,
        introduction,
        projectBudget,
        entrustPart,
        projectBudgetUnit,
        projectName
      }
      if (this.type === 'change') {
        params.projectId = this.projectId
        changeProjectInfo(params).then(res => {
          if (res.code === 200) {
            this.dialogVisible = false
            this.$emit('publishSuccess', '修改成功')
          } else {
            this.$message.error(res.msg)
          }
        })
      } else {
        publishProject(params).then(res => {
          if (res.code === 200) {
            this.dialogVisible = false
            this.$emit('publishSuccess', '发布成功')
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.publish-content {
  user-select: none;
  .title {
    padding-left: 20px;
    margin-bottom: 31px;
    img {
      width: 31px;
      height: 30px;
      margin-top: 2px;
      margin-right: 10px;
      display: inline-block;
      font-size: 0;
    }
    p {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 33px;
      display: inline-block;
      vertical-align: top;
    }
  }
  .options {
    margin-bottom: 20px;
    position: relative;
    .name {
      width: 100px;
      height: 50px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 50px;
      position: absolute;
      left: 0;
      top: 0;
      text-align: right;
    }
    .required:before {
      content: '*';
      color: #f56c6c;
      margin-right: 4px;
    }
    .item {
      margin-left: 121px;
      .budget {
        width: 200px;
        margin-right: 20px;
      }
      .tags {
        overflow: hidden;
      }
      .tag {
        width: 120px;
        height: 36px;
        margin-top: 20px;
        margin-right: 10px;
        float: left;
        border-radius: 30px;
        color: #999999;
        border: 1px solid #dcdfe6;
        text-align: center;
        line-height: 36px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        cursor: pointer;
      }
      .select-tag {
        font-size: 16px;
        background-color: RGBA(90, 211, 205, 1);
        color: #ffffff;
      }
    }
  }
  .content {
    padding: 0 0 0 110px;
  }
  .submit {
    background-color: rgb(133, 253, 247);
    width: 446px;
    height: 46px;
    background: #5ad3cd;
    border-radius: 23px;
    margin: 40px 0 0 233px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
    text-align: center;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.budget-unit .el-input {
  width: 200px;
}

.el-link--info{
line-height: 50px;
}

.platform_fees{
  line-height: 50px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
</style>
