<template lang="pug">
.set-content
  dialogMask(:show.sync="showDialog", :showClose="true")
    .title
      img(src="../../../../assets/gujia@2x.png" alt="")
      p 平台详细估价
    .desc
      img(src="../../../../assets/tanhao-2@2x.png")
      span 平台估价仅供参考，实际以各投标方报价为准
    .content(v-for="(item, index) in list" :key="`${index}evaluateDetail1`")
      .name {{item.groupName}}
      .price(v-for="(itm, idx) in item.platformQuotationInfoVoList" :key="`${idx}evaluateDetail2`")
        .price-name {{itm.quotationName}}
        .price-value {{itm.projectBudget}} 元
        img.del(src="./../../../../assets/guanbi3 copy 5@2x.png", v-if="jurisdictionCode.includes(1)"  @click="del(itm.quotationId)")

</template>

<script>
import dialogMask from '@/components/common/dialog.vue'
import {delPrice} from '@/api/project'
export default {
  name: 'evaluate',
  props: ['projectId', 'showEvaluateDialog', 'list', 'jurisdictionCode'],
  data() {
    return {
      showDialog: false
    }
  },
  watch: {
    showEvaluateDialog(val) {
      if (val) this.showDialog = true
    },
    showDialog(val) {
      if (!val) {
        this.$emit('update:showEvaluateDialog', false)
      }
    }
  },
  components: {
    dialogMask
  },
  methods: {
    del(quotationId) {
      delPrice({
        quotationId,
        projectId: this.projectId
      }).then(res => {
        console.log('res ', res)
        this.$emit('uploadSuccess')
        this.$message.success('删除成功')
        this.showDialog = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.set-content {
  user-select: none;
  .title {
    padding-left: 40px;
    margin-bottom: 19px;
    img {
      width: 30px;
      height: 34px;
      margin-right: 10px;
      display: inline-block;
      font-size: 0;
    }
    p {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 33px;
      display: inline-block;
      vertical-align: top;
    }
  }
  .desc {
    width: 490px;
    height: 48px;
    margin-left: 40px;
    background: #f9f9f9;
    border-radius: 24px;
    margin-bottom: 37px;
    img {
      width: 24px;
      height: 24px;
      background: #dedede;
      display: inline-block;
      margin-left: 20px;
      vertical-align: top;
      margin-top: 12px;
    }
    span {
      margin-left: 10px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 48px;
      display: inline-block;
      vertical-align: top;
    }
  }
  .price {
    margin: 0 116px 30px 155px;
    overflow: hidden;
    .price-name {
      width: 250px;
      font-size: 30px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #5ad3cd;
      line-height: 42px;
      float: left;
    }
    .price-value {
      float: left;
      margin-left: 20px;
      font-size: 30px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ff724c;
      line-height: 42px;
    }
    .del {
      float: right;
      width: 30px;
      margin-top: 6px;
      cursor: pointer;
    }
  }
  .name {
    width: 800px;
    height: 60px;
    background: rgba(255, 114, 76, 0.08);
    box-sizing: border-box;
    padding-left: 40px;
    font-size: 26px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ff724c;
    line-height: 60px;
    margin-bottom: 40px;
  }
  .content {
    width: 100%;
  }
  .textarea {
    height: 180px;
  }
  .submit {
    background-color: rgb(133, 253, 247);
    width: 446px;
    height: 46px;
    background: #5ad3cd;
    border-radius: 23px;
    margin: 60px 128px 0 226px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
    text-align: center;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.budget-unit .el-input {
  width: 200px;
}
.half {
  width: 200px !important;
  .el-input {
    width: 200px;
  }
}
.right {
  margin-right: 40px;
}
</style>
