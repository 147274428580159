/* eslint-disable no-debugger */
<template lang="pug">
.set-content
  dialogMask(:show.sync="showDialog", :showClose="true")
    .title
      img(src="../../../assets/projectPlan.png" alt="")
      .title-desc 设置计划
    .content-top
      span(v-if="planList.length !== 0 && statusLength.start > 0" :style="{top: statusLength.start + 'px'}") 进行中
      span(v-if="planList.length !== 0 && statusLength.complete > 0" :style="{top: statusLength.complete + 'px'}") 已完成
      span(v-if="planList.length !== 0 && statusLength.unStart > 0" :style="{top: statusLength.unStart + 'px'}") 未开始
      .project-list
        draggable(v-model="planList" @end="end")
          //- @click="changeInfo"
          .project-list-item(v-for="(item, index) in planList" :key="item.id" @click="changePlan(item)")
            .project-status {{item.name}}
            .project-status-time-plan(v-if="planList.length > 0") 计划 {{item.plannedFinishDateShow}}
            .project-status-time-actual(v-if="planList.length > 0") 实际 {{item.actualFinishDateShow}}
            .setUnderway(v-if="item.status === '未开始'" @click.stop="handleSetUnderway(item.id)") 设为进行中
            .setUnderway(v-if="item.status === '进行中'" @click.stop="handleSetComplete(item.id)") 设为完成
            .setUnderway(v-if="item.status === '已完成'" @click.stop="handleSetDoing(item.id)") 设为未开始
            .recall(@click="handleReacall(item.id)") 删除
    el-divider.el-divider
    .content-bottom
      .option
        p 节点名称
        el-input.select(v-model="form.name")
      .option
        p 计划完成日期
        el-date-picker.select(
          v-model="form.plannedFinishDate"
          type="date"
          placeholder="选择日期"
          clearable
        )  
      .option
        p 实际完成日期
        el-date-picker.select(
          v-model="form.actualFinishDate"
          type="date"
          placeholder="选择日期"
          clearable
        )  
    .submit(@click="submit") {{change ? '修改节点' : '增加节点'}}
</template>

<script>
import dialogMask from '@/components/common/dialog.vue'
import {
  getProjectPlanList,
  setProjectPlanUnderway,
  setProjectPlanComplete,
  setProjectPlanNotStarted,
  changeOrder,
  setProjectPlanRecall,
  addProjectPlan,
  changePlan
} from '../../../api/project'
import draggable from 'vuedraggable'
export default {
  name: 'set-project-plan-dialog',
  props: ['projectId', 'showSetProjectDialog'],
  components: {
    dialogMask,
    draggable
  },
  data() {
    return {
      showDialog: false,
      form: {
        name: '',
        plannedFinishDate: '',
        actualFinishDate: ''
      },
      planList: [],
      statusLength: {
        unStart: 0,
        start: 0,
        complete: 0
      },
      change: false
    }
  },
  watch: {
    showSetProjectDialog(val) {
      if (val) this.showDialog = true
    },
    showDialog(val) {
      if (!val) {
        this.$emit('update:showSetProjectDialog', false)
        this.reset()
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    reset() {
      this.form = {
        name: '',
        plannedFinishDate: '',
        actualFinishDate: ''
      }
      this.change = false
    },
    // changeInfo() {},
    end(info) {
      const params = []
      this.planList.forEach(item => {
        params.push({
          planId: item.id
        })
      })
      params[info.newIndex].isDrag = true
      changeOrder(params).then(res => {
        if (res.code === 200) {
          this.init()
        }
      })
    },
    changePlan(info) {
      this.change = true
      this.id = info.id
      this.form = {
        name: info.name,
        plannedFinishDate: info.plannedFinishDate ? new Date(info.plannedFinishDate.replace(/-/g, '/')) : '',
        actualFinishDate: info.actualFinishDate ? new Date(info.actualFinishDate.replace(/-/g, '/')) : ''
      }
    },
    init() {
      const params = {
        projectId: this.projectId
      }
      getProjectPlanList(params).then(res => {
        const data = res.data || []
        const statusLength = {
          unStart: 0,
          start: 0,
          complete: 0
        }
        data.forEach(item => {
          item.actualFinishDateShow = item.actualFinishDate ? this.formatDate(item.actualFinishDate) : ''
          item.plannedFinishDateShow = item.plannedFinishDate ? this.formatDate(item.plannedFinishDate) : ''
          if (item.status === '进行中') statusLength.start++
          if (item.status === '未开始') statusLength.unStart++
          if (item.status === '已完成') statusLength.complete++
        })
        this.statusLength = {
          unStart:
            statusLength.unStart > 0
              ? (statusLength.complete + statusLength.start) * 76 + (statusLength.unStart * 76 - 42) / 2
              : 0,
          complete: statusLength.complete > 0 ? (statusLength.complete * 76 - 42) / 2 : 0,
          start: statusLength.start > 0 ? (statusLength.start * 76 - 42) / 2 + statusLength.complete * 76 : 0
        }
        this.planList = data
      })
    },
    // 设置为进行中
    handleSetUnderway(id) {
      const params = {
        planId: id
      }
      setProjectPlanUnderway(params).then(res => {
        if (res.code === 200) {
          this.init()
        }
      })
    },
    handleSetUnStart(id) {
      const params = {
        planId: id
      }
      setProjectPlanUnderway(params).then(res => {
        if (res.code === 200) {
          this.init()
        }
      })
    },
    handleSetComplete(id) {
      const params = {
        planId: id
      }
      setProjectPlanComplete(params).then(res => {
        if (res.code === 200) {
          this.init()
        }
      })
    },
    handleSetDoing(id) {
      const params = {
        planId: id
      }
      setProjectPlanNotStarted(params).then(res => {
        if (res.code === 200) {
          this.init()
        }
      })
    },
    // 撤回
    handleReacall(id) {
      const params = {
        planId: id
      }
      setProjectPlanRecall(params).then(res => {
        if (res.code === 200) {
          this.init()
          this.$message.success('删除成功')
        }
      })
    },
    formatDate(time) {
      const result = new Date(time.replace(/-/g, '/'))
      const month = result.getMonth() + 1
      const day = result.getDate() > 9 ? result.getDate() : `0${result.getDate()}`
      return `${month}.${day}`
    },
    submit() {
      if (!this.change) {
        const params = Object.assign({}, this.form, {
          projectId: this.projectId
        })
        addProjectPlan(params).then(res => {
          if (res.code === 200) {
            this.init()
            this.$parent.init()
          }
        })
      } else {
        const params = Object.assign({}, this.form, {
          projectId: this.projectId,
          id: this.id
        })
        changePlan(params).then(res => {
          if (res.code === 200) {
            this.init()
            this.$parent.init()
            this.reset()
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.set-content {
  user-select: none;
  .title {
    padding: 40px 30px 0 40px;
    img {
      height: 29px;
      width: 30px;
      margin-right: 2px;
      margin-top: 2px;
      display: inline-block;
      font-size: 0;
    }
    .title-desc {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 33px;
      display: inline-block;
      vertical-align: top;
    }
  }
  .complete {
    visibility: hidden;
  }
  .content-top {
    margin: 72px 0px 31px 40px;
    position: relative;
    span {
      display: inline-block;
      padding: 6px 25px;
      background: #5ad3cd;
      border-radius: 4px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      margin-right: 20px;
      vertical-align: middle;
      position: absolute;
      left: 0;
      transform: translateY(-50%);
    }
    .project-list {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 130px);
      text-align: left;
      position: relative;
      margin-left: 133px;
      .project-list-item {
        margin: 0 0 42px 0;
        cursor: pointer;
        .setUnderway {
          width: 130px;
          display: inline-block;
          font-size: 20px;
          text-align: center;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          padding: 3px 15px;
          background: #5ad3cd;
          border-radius: 4px;
          margin-right: 20px;
          cursor: pointer;
        }

        .recall {
          display: inline-block;
          padding: 3px 20px;
          background: #ff724c;
          border-radius: 4px;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }

        .project-status {
          width: 140px;
          display: inline-block;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .project-status-time-plan {
          width: 104px;
          display: inline-block;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
        .project-status-time-actual {
          width: 106px;
          display: inline-block;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }
    }

    .setUnderway {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      padding: 3px 15px;
    }

    .recall {
      padding: 3px 20px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
  }

  .el-divider {
    width: 100%;
    height: 1px;
    border: 1px solid #dedede;
    margin: 0;
  }

  .content-bottom {
    padding: 81px 108px 21px 109px;
    .option {
      margin-bottom: 51px;
      position: relative;
      p {
        font-size: 20px;
        height: 50px;
        line-height: 50px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        position: absolute;
        text-align: left;
      }
      .select {
        display: inline-block;
        margin: 0 42px 0 141px;
      }
    }
  }
}
.submit {
  background-color: rgb(133, 253, 247);
  width: 446px;
  height: 46px;
  background: #5ad3cd;
  border-radius: 23px;
  margin: 0 auto 0;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 46px;
  text-align: center;
  cursor: pointer;
}
</style>
<style lang="scss">
.option .el-input {
  width: 200px;
}
</style>
