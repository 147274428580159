/* eslint-disable prettier/prettier */
<template lang="pug">
.project-comment
  .project-comment-title
    p.name 评价
  el-divider.el-divider
  .project-comment-content
    .project-comment-content-item(v-for="(item, index) in commentList" :key="`${index}projectComment`")
      .project-comment-content-item-basic
        .project-comment-content-item-basic-name 需求方
        .project-comment-content-item-basic-name 评价
        .name
          .project-comment-content-item-basic-name {{item.objectName}}
          .project-comment-content-item-basic-objectName {{item.objectType === 1 ? '公司' : item.objectType === 2 ? '团队' : '个人'}}
        .project-comment-content-item-basic-score(v-for="i in item.score" :key="`${i}111`")
          img(src="../../../assets/start-comment.png" alt="")
        .project-comment-content-item-basic-score(v-for="i in (5 - item.score)" :key="`${i}222`")
        .project-comment-content-item-basic-time {{item.createTime}}
      .project-comment-content-item-basic-content
        span {{item.evaluation}}
        img(src="../../../assets/guanbi2x.png" @click="handleDelete(item.id)" v-if="jurisdictionCode.includes(0)")
    .comment-btn(@click="handleComment" v-if="jurisdictionCode.includes(0) && winList.length > 0") 写评价
  comment-dialog(
    :projectId="projectId",
    :showCommentDialog.sync="showDialog",
    @update="update"
  )
</template>

<script>
import {getProjectCommentList, deleteComment, getWinList} from '../../../api/project.js'
import commentDialog from './commentDialog.vue'
import {formatTimeFilter} from './../../../utils/index'
export default {
  name: 'project-plan',
  components: {commentDialog},
  props: ['projectId', 'jurisdictionCode', 'status'],
  data() {
    return {
      commentList: [],
      showDialog: false,
      winList: []
    }
  },
  created() {
    this.init()
    getWinList({
      projectId: this.projectId
    }).then(res => {
      if (res.code === 200) {
        this.winList = res.data
      }
    })
  },
  methods: {
    init() {
      const params = {
        projectId: this.projectId
      }
      getProjectCommentList(params).then(res => {
        const data = res.data || []
        data.forEach(item => {
          item.createTime = this.formatDate(item.createTime)
        })
        this.commentList = data
      })
    },
    handleComment() {
      this.showDialog = true
    },
    handleDelete(id) {
      const params = {
        projectId: this.projectId,
        id: id
      }
      deleteComment(params).then(res => {
        if (res.code === 200) {
          this.$message.success('删除成功')
          this.init()
        }
      })
    },
    update() {
      this.init()
    },
    formatDate(time) {
      return formatTimeFilter(time, 'MM-DD HH:mm')
      // const result = new Date(time)
      // const month = result.getMonth() + 1
      // const day = result.getDate() > 9 ? result.getDate() : `0${result.getDate()}`
      // const hour = result.getHours() + 1 > 9 ? result.getHours() + 1 : `0${result.getHours()}`
      // const minute = result.getMinutes() > 9 ? result.getMinutes() : `0${result.getMinutes()}`
      // return `${month}.${day} ${hour}:${minute}`
    }
  }
}
</script>

<style lang="scss" scoped>
.project-comment {
  background-color: #fff;
  border-radius: 8px;
  padding: 40px 80px 54px 80px;
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;

  .project-comment-title {
    height: 30px;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 30px;
    display: flex;
    justify-content: space-between;
  }
  .el-divider {
    height: 1px;
    margin: 30px 0 40px 0;
  }
  .project-comment-content {
    .name {
      vertical-align: top;
      display: inline-block;
      width: 200px;
    }
    .project-comment-content-item-basic {
      position: relative;
      margin-bottom: 28px;
      .project-comment-content-item-basic-name {
        vertical-align: top;
        display: inline-block;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-right: 20px;
      }
      .project-comment-content-item-basic-objectName {
        vertical-align: top;
        display: inline-block;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        margin-left: -16px;
      }
      .project-comment-content-item-basic-score {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 2px 7px 3px 0;
        img {
          width: 20px;
          height: 20px;
          display: inline-block;
        }
      }
      .project-comment-content-item-basic-time {
        display: inline-block;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        vertical-align: top;
        margin-left: 140px;
      }
    }
    .project-comment-content-item-basic-content {
      margin-bottom: 50px;
      span {
        display: inline-block;
        width: calc(100% - 64px);
        vertical-align: middle;
      }
      img {
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        margin-right: 2px;
        margin-left: 42px;
        cursor: pointer;
      }
    }
    .comment-btn {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      padding: 4px 79px;
      background: #ffaa30;
      border-radius: 14px;
      width: 200px;
      margin: 0 auto;
      cursor: pointer;
    }
  }
}
</style>
