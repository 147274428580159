<template lang="pug">
.comp-info-box
  .comp-info-container
    .comp-info-point
    .comp-info-title 投标方
  .bid-customer-detail(v-for="(item, index) in customerList" :key="`${index}bidCustomer`")  
    .customer-basic-info
      img(:src="item.logoPath" @click="handelToDeatil(item.tendererType, item.tendererId)")
      .customer-basic-info-text
        .customer-basic-info-text-company-name(@click="handelToDeatil(item.tendererType, item.tendererId)") {{item.tendererName}}
          .customer-teams_name {{item.tendererType === 1 ? '' : item.tendererType === 2 ? '团队' : ''}}
        .customer-basic-info-cost 
          .customer-basic-info-text-money {{item.quotation ? resetBudget(item.quotation) : `${resetBudget(item.quotationMin)}-${resetBudget(item.quotationMax)}`}}
          .customer-basic-info-text-day(v-if="item.workload && item.durationMonth") {{item.workload}}{{item.workloadUnit === 1 ? '人天' : '小时'}}（量）&nbsp;&nbsp;&nbsp;{{item.durationMonth > 0 ? `${item.durationMonth}个月` : ''}}{{item.durationDay ? `${item.durationDay}天（期）` : ''}}
          .customer-basic-info-text-day {{item.customerDurationExceptionMonth > 0 ? `${item.customerDurationExceptionMonth}个月` : ''}} {{item.customerDurationExceptionDay ? `${item.customerDurationExceptionDay}天` : ''}}
        .customer-basic-info-extra(v-if="item.postscript" @click="handleExtra(item)") 附言>>
        .customer-basic-info-people(@click="handleCheckPeople(item.id, item.hasTendererAuth, true)" v-if="item.tendererType !== 1") {{item.memberCount}}个成员
      .customer-demand
        .customer-demand-detail.num(v-if="item.draftNum > 0" @click="handleUploadSample(item, item.isWin ? false : item.hasTendererAuth)") {{item.draftNum}} 个样稿
        .customer-demand-detail(v-if="item.standardSatisfactionRate") {{item.standardSatisfactionRate}}%满足标准
        .customer-demand-detail(v-if="item.realCase") 案例真实
        .customer-demand-detail(v-if="item.provideSourceCode") 提供源文件
        .customer-demand-detail(v-if="item.provideCodeExplication") 配备代码解说
        .customer-demand-detail(v-if="item.tenderTime") {{item.tenderTime}}投标
      img.bid-or-not-img(v-if="item.isWin", src="./../../../assets/WechatIMG148.png")
      //- 修改投标
      .bid-operate-button(v-if="statusList[status] !== '已停止' && !item.isWin")
        .btn(@click="handleInviteSample(item)" v-if="!item.draftInvited && (jurisdictionCode.includes(0) || jurisdictionCode.includes(1)) && !item.draftApplied") 邀请样稿
        .btn(:class="item.draftInvited ? 'agree' : ''" v-if="item.draftInvited && (jurisdictionCode.includes(0) || jurisdictionCode.includes(1)) && !item.draftApplied") 已邀请样稿
        //- 需要未同意样稿申请 + 已申请提供样稿，权限是客户 + 运营（0 || 1）
        .btn(@click="handleProvideTenderer(item)" v-if="!item.draftApplicationAllowed && item.draftApplied && (jurisdictionCode.includes(0) || jurisdictionCode.includes(1))") 同意样稿申请

        //- 需要有投标方权限 + 未同意样稿邀请，已邀请样稿，权限是投标方（中标 || 未中标）
        .btn(@click="handleAcceptInviteSample(item)" v-if="!item.draftInvitationAllowed && item.draftInvited && item.hasTendererAuth") 同意提供样稿
        //- 需要有投标方权限 + 没申请投标，权限是投标方（中标 || 未中标）  未邀请样稿
        .btn(@click="handleSApplySample(item)" v-if="item.hasTendererAuth && !item.draftApplied && item.hasTendererAuth && !item.draftInvited") 申请提供样稿
        //- 需要有投标方权限 + 没申请投标，权限是投标方（中标 || 未中标）  未邀请样稿
        .btn(:class="item.draftApplicationAllowed ? 'agree' : ''" v-if="item.hasTendererAuth && item.draftApplied && !item.draftInvited") 已申请提供样稿
        //- 需要有投标方权限 + 没申请投标，权限是投标方（中标 || 未中标）
        .btn(@click="handleUploadSample(item, item.hasTendererAuth)" v-if="item.hasTendererAuth && (item.draftApplicationAllowed || item.draftInvitationAllowed)") 上传样稿
        .btn(@click="handleCheckPeople(item.id, item.hasTendererAuth)" v-if="item.hasTendererAuth && item.tendererType !== 1") 设置成员
        .btn.danger(v-if="(jurisdictionCode.includes(0) || jurisdictionCode.includes(1))") 
          el-popconfirm(
            type="text"
            @confirm="handleRemove(item)"
            title="确定移除么？"
          )
            div(slot="reference") 移除
        .btn.danger(@click="handleChange(item)" v-if="item.hasTendererAuth") 修改
        .btn.danger(v-if="item.hasTendererAuth") 
          el-popconfirm(
            type="text"
            @confirm="handleSetEvaluate(item)"
            title="确定撤回么？"
          )
            div(slot="reference") 撤回
          
        .btn(@click="handleWinBid(item)" v-if="(jurisdictionCode.includes(0) || jurisdictionCode.includes(1)) && !item.isWin") 中标
    .line(v-if="(index + 1) !== customerList.length")
  //- .bid-change-button 修改投标
  postscriptDialog(
    :showPostscriptDialog.sync="showPostscriptDialog",
    :postscript="postscript"
  )
  member(
    :projectId="projectId",
    @uploadSuccess="uploadSuccess",
    :info="tendererList"
    :tendererId="tendererId"
    :showAction="showAction"
    :showMemberDialog.sync="showMemberDialog",
  )
  changeInfo(
    :projectId="projectId",
    @uploadSuccess="uploadSuccess",
    :finalVersion="finalVersion"
    :info="changeTenderInfo"
    :tendererId="tendererId"
    :showChangeDialog.sync="showChangeDialog",
  )
  updateWord(
    :hasTendererAuth="hasTendererAuth"
    :showUpdateWord.sync="showUpdateWord",
    :tendererId="tendererId"
    :projectId="projectId",
    @uploadSuccess="uploadSuccess",
  )
</template>

<script>
import {
  getBidTenders,
  getTendererList,
  applyTenderer,
  approveTenderer,
  provideTenderer,
  win,
  remove,
  tenderRequestRemove,
  invited
} from '@/api/project'
import postscriptDialog from '@/components/project/projectCompInfo/dialog/postscript.vue'
import updateWord from '@/components/project/projectCompInfo/dialog/updateWord.vue'
import member from '@/components/project/projectCompInfo/dialog/member.vue'
import changeInfo from '@/components/project/projectCompInfo/dialog/changeInfo.vue'
// import {statusList} from '@/config/status.js'

export default {
  name: 'bidCustomer',
  props: ['projectId', 'jurisdictionCode', 'status', 'finalVersion'],
  data() {
    return {
      customerList: [],
      showPostscriptDialog: false,
      showMemberDialog: false,
      showChangeDialog: false,
      showUpdateWord: false,
      hasTendererAuth: false,
      statusList: '',
      tendererList: [],
      showAction: false,
      changeTenderInfo: '',
      tendererId: '',
      postscript: ''
    }
  },
  created() {
    this.init()
  },
  components: {
    member,
    updateWord,
    changeInfo,
    postscriptDialog
  },
  watch: {
    showMemberDialog(val) {
      if (!val) {
        setTimeout(() => {
          this.showAction = false
        }, 300)
      }
    }
  },
  methods: {
    init() {
      const params = {
        projectId: this.projectId
      }
      getBidTenders(params).then(res => {
        this.customerList = res.data
        console.log('res.data1111 ', res.data)
      })
    },
    resetBudget(budget) {
      if (budget === '') return '未知'
      if (budget < 10000) return `${budget}元`
      return `${budget / 10000}万`
    },
    handelToDeatil(type, id) {
      if (type === 1) {
        this.$router.push({
          path: `/companyDetail/${id}`
        })
      } else {
        this.$router.push({
          path: `/teams/detail/${id}`
        })
      }
    },
    handleRemove(info) {
      remove({
        projectId: this.projectId,
        tendererId: info.id
      }).then(res => {
        console.log('res ', res)
        if (res.code === 200) {
          this.$message.success('移除成功')
          this.init()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleExtra(info) {
      console.log('info ', info)
      this.showPostscriptDialog = true
      this.postscript = info.postscript
    },
    handleChange(info) {
      this.changeTenderInfo = info
      this.tendererId = info.id
      this.showChangeDialog = true
    },
    handleSetEvaluate(info) {
      tenderRequestRemove({
        projectId: this.projectId,
        tenderId: info.id
      }).then(res => {
        console.log('res ', res)
        if (res.code === 200) {
          this.$message.success('已撤回')
          this.init()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleCheckPeople(id, hasTendererAuth, show) {
      if (!hasTendererAuth && !show) return
      this.showAction = show
      this.showMemberDialog = true
      this.tendererId = id
      getTendererList({tendererId: id}).then(res => {
        this.tendererList = res.data
      })
    },
    // 邀请样稿
    handleInviteSample(info) {
      invited({
        projectId: this.projectId,
        tendererId: info.id
      }).then(res => {
        console.log('res ', res)
        if (res.code === 200) {
          this.$message.success('已发送邀请')
          this.init()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 同意邀请样稿
    handleAcceptInviteSample(info) {
      approveTenderer({
        projectId: this.projectId,
        tendererId: info.id
      }).then(res => {
        console.log('res ', res)
        if (res.code === 200) {
          this.$message.success('已同意提供样稿')
          this.init()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 同意样稿申请 provideTenderer
    handleProvideTenderer(info) {
      provideTenderer({
        projectId: this.projectId,
        tendererId: info.id
      }).then(res => {
        console.log('res ', res)
        if (res.code === 200) {
          this.$message.success('已同意样稿申请')
          this.init()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    uploadSuccess() {
      this.init()
    },
    // 中标
    handleWinBid(info) {
      this.$confirm('确认中标？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        win({
          projectId: this.projectId,
          tendererId: info.id
        }).then(res => {
          console.log('res ', res)
          if (res.code === 200) {
            this.$message.success('已中标')
            this.init()
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    // 上传样稿
    handleUploadSample(info, hasTendererAuth) {
      this.hasTendererAuth = hasTendererAuth
      this.tendererId = info.id
      this.showUpdateWord = true
    },
    // 申请提供样稿
    handleSApplySample(info) {
      applyTenderer({
        projectId: this.projectId,
        tendererId: info.id
      }).then(res => {
        console.log('res ', res)
        if (res.code === 200) {
          this.$message.success('已申请提供样稿')
          this.init()
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.comp-info-box {
  margin: 40px 0;
  .agree {
    color: #999999 !important;
    border: 1px solid #999999 !important;
  }
  .comp-info-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .comp-info-point {
      width: 9px;
      height: 9px;
      background: #5ad3cd;
      margin: 10px 11px 10px 0;
    }
    .comp-info-title {
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
  }
  .bid-customer-detail {
    padding: 20px;
    position: relative;
    overflow: hidden;
    .line {
      height: 0;
      width: 100%;
      border-bottom: 1px solid rgba(241, 241, 241, 1);
      margin: 29px 0 0;
    }
    .customer-demand {
      width: 244px;
      overflow: hidden;
      position: absolute;
      top: 20px;
      left: 440px;
      .customer-demand-detail {
        background: #f1f1f1;
        border-radius: 2px;
        padding: 4px 6px;
        float: left;
        margin-right: 10px;
        margin-bottom: 5px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
      }
      .num {
        background: #5ad3cd;
        color: #ffffff;
        cursor: pointer;
      }
    }
    .bid-or-not-img {
      width: 85px;
      position: absolute;
      top: 10px;
      right: 20px;
    }
    .customer-basic-info {
      overflow: hidden;
      img {
        width: 80px;
        height: 60px;
        display: inline-block;
        float: left;
        cursor: pointer;
      }
      .customer-basic-info-text {
        text-align: left;
        font-size: 14px;
        margin-left: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        float: left;
        .customer-basic-info-text-company-name {
          vertical-align: top;
          margin-bottom: 12px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 22px;
          cursor: pointer;
          .customer-teams_name {
            display: inline-block;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 22px;
            margin-left: 6px;
          }
        }
        .customer-basic-info-text-money {
          display: inline-block;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 20px;
          margin-right: 10px;
        }
        .customer-basic-info-text-day {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          display: inline-block;
        }
        .customer-basic-info-text-day-total {
          display: inline-block;
          font-weight: 400;
          color: #666666;
        }
        .customer-basic-info-extra,
        .customer-basic-info-people {
          cursor: pointer;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #5ad3cd;
          line-height: 20px;
          margin-top: 12px;
        }
      }
    }
  }
  .bid-operate-button {
    width: 218px;
    float: right;
    overflow: hidden;
  }
  .btn {
    border-radius: 12px;
    border: 1px solid #5ad3cd;
    padding: 3px 8px;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #5ad3cd;
    line-height: 17px;
    float: right;
    margin-right: 10px;
    margin-bottom: 12px;
    cursor: pointer;
  }
  .bid-change-button {
    width: 200px;
    height: 28px;
    background: #ffaa30;
    border-radius: 14px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 28px;
    text-align: center;
    margin: 0 auto;
  }
  .danger {
    border: 1px solid #ff724c;
    color: #ff724c;
  }
}
</style>
