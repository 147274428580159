<template lang="pug">
.project-detail
  .content(v-show="projectInfo")
    .project-type {{businessList.softwareDevelopmentList[projectInfo.businessType - 1] && businessList.softwareDevelopmentList[projectInfo.businessType - 1].name}}
    .title
      p.name {{projectInfo.name}}
      ul.setting
        li(@click="setProject" v-if="jurisdictionCode.includes(1)")
          img(src="./../../../assets/setting.png")
          p 设置
        li(@click="change" v-if="jurisdictionCode.includes(1) || jurisdictionCode.includes(0)")
          img(src="./../../../assets/change.png")
          p 修改
        li(@click="setStatus(false)", v-if="(jurisdictionCode.includes(1) || jurisdictionCode.includes(0)) && statusList[projectInfo.status] !== '已停止'")
          img(src="./../../../assets/stop.png")
          p 停止
        li(@click="setStatus(true)", v-if="(jurisdictionCode.includes(1) || jurisdictionCode.includes(0)) && statusList[projectInfo.status] === '已停止'")
          img(src="./../../../assets/start.png")
          p 启动
    ul.info-list
      li
        p.label 需求方：
        p.name {{projectInfo.customerName}}
      li
        p.label 承接方：
        p.name  {{ projectInfo.undertakeParty ? projectInfo.undertakeParty.join('、') : '暂无'}}
      li
        p.label 合作方式：
        p.name {{teamworkList[projectInfo.cooperationWay - 1] && teamworkList[projectInfo.cooperationWay - 1].name}}
      li
        p.label 委托环节：
        p.name 
          span(v-for="(part, index) in projectInfo.entrustPart") {{handlepart(part)}}{{projectInfo.entrustPart.length - 1 === index ? '' : '、'}}
      li
        p.label 项目预算：
        p.name {{resetBudget(projectInfo.budget)}}
      li
        p.label 发布时间：
        p.name {{projectInfo.publishDate}}
      li
        p.label.statue-label 当前状态：
        p.name.status {{statusList[projectInfo.status]}}
    .sub-title 简介 
    p.introduction {{projectInfo.introduction}}
    publishDialog(
      :showPublish.sync="showPublishDialog", 
      :partList="partList",
      :businessList="businessList.softwareDevelopmentList",
      :teamworkList="teamworkList",
      :projectInfo="projectInfo",
      :projectId="projectId"
      type="change"
      @publishSuccess="publishSuccess"
    )
    projectSetDialog(
      :showProjectSetDialog.sync="showProjectSetDialog", 
      :projectInfo="projectInfo",
      :projectId="projectId",
      @setSuccess="publishSuccess"
    )
</template>

<script>
import {formatTimeFilter} from '@/utils/index.js'
import {statusList} from '@/config/status.js'
import publishDialog from '@/components/project/publishDialog'
import projectSetDialog from '@/components/project/projectSetDialog'
import {projectEntrustPart} from '@/api/project'
import {getBusinessListApi} from '@/api/index'

export default {
  name: 'projectHead',
  components: {
    publishDialog,
    projectSetDialog
  },
  props: ['projectInfo', 'projectId', 'jurisdictionCode', 'status'],
  data() {
    return {
      statusList,
      showPublishDialog: false,
      showProjectSetDialog: false,
      partList: [],
      businessList: [
        {
          name: ''
        }
      ],
      teamworkList: [
        {
          name: '整体外包',
          id: 1
        },
        {
          name: '联合开发',
          id: 2
        }
      ]
    }
  },
  watch: {
    projectInfo: {
      handler(val) {
        // eslint-disable-next-line no-debugger
        console.log('val ', val)
      },
      deep: true
    }
  },
  created() {
    projectEntrustPart().then(res => {
      this.partList = res.data
      getBusinessListApi().then(res => {
        this.businessList = res.data
      })
    })
  },
  methods: {
    formatTimeFilter,
    resetBudget(budget) {
      if (!budget) return '未知'
      if (budget < 10000) return `${budget}元`
      return `${budget / 10000}万`
    },
    handlepart(part) {
      let tag = ''
      this.partList.forEach(res => {
        if (res.id === Number(part)) {
          tag = res.name
        }
      })
      if (!tag) tag = part
      return tag
    },
    setProject() {
      this.showProjectSetDialog = true
    },
    setStatus(status) {
      this.$emit('setStatus', status)
    },
    change() {
      this.showPublishDialog = true
    },
    publishSuccess(message) {
      this.$message.success(message)
      this.$emit('publishSuccess')
    }
  }
}
</script>

<style lang="scss" scoped>
$radius: 8px;
$fontFamily: PingFangSC-Medium, PingFang SC;
$btnColor: #55cbc4;
.project-detail {
  background: #ffffff;
  border-radius: 8px;
  padding: 25px 80px 40px 80px;
  position: relative;
  margin-bottom: 20px;
  min-height: 445px;
  .project-type {
    width: 64px;
    height: 40px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    border-radius: 0px 100px 100px 0px;
    background: #5ad3cd;
    position: absolute;
    top: 20px;
    left: 0;
  }
  .title {
    overflow: hidden;
    .name {
      height: 30px;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 30px;
      float: left;
    }
    .setting {
      float: right;
      li {
        height: 30px;
        padding: 3px 0;
        box-sizing: border-box;
        float: left;
        margin-left: 30px;
        vertical-align: top;
        cursor: pointer;
        img {
          width: 18px;
          height: 18px;
          display: inline-block;
          margin-right: 8px;
          margin-top: 3px;
        }
        p {
          font-size: 17px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
          display: inline-block;
          vertical-align: top;
        }
      }
    }
  }
  .info-list {
    margin-top: 40px;
    margin-bottom: 22px;
    overflow: hidden;
    li {
      width: 500px;
      float: left;
      margin-bottom: 16px;
    }
    .label,
    .name {
      vertical-align: top;
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 25px;
      display: inline-block;
    }
    .label {
      width: 90px;
      color: #999999;
      margin-left: 10px;
    }
    .name {
      max-width: 350px;
      color: #333333;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .status {
      height: 30px;
      border-radius: 15px;
      border: 1px solid #5ad3cd;
      width: auto;
      line-height: 28px;
      padding: 0 19px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #37c2bc;
    }
    .statue-label {
      height: 30px;
      line-height: 30px;
    }
  }
  .sub-title {
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 28px;
    padding-bottom: 20px;
    border-bottom: 2px solid #f1f1f1;
    margin-bottom: 19px;
  }
  .introduction {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 25px;
    word-wrap: break-word;
  }
}
</style>
