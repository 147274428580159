/* eslint-disable prettier/prettier */
<template lang="pug">
.project-interface-case
  .project-interface-case-title
    p.name 界面案例
  el-divider.el-divider
  .project-interface-case-content(v-if="showProjectCase || (jurisdictionCode.includes(0) || jurisdictionCode.includes(1))")
    draggable(v-model="caseList")
      .project-interface-case-item(v-for="(item, index) in caseList" :key="index")
        img.project-interface-img(alt="" :src="item.picUrl" @click="openTab(item.picUrl)")
        img.project-interface-img-delete(src="../../../assets/delete.png" alt="" @click="handleDeleteInterface(item)" v-if="jurisdictionCode.includes(0) || jurisdictionCode.includes(1)")
  p.notice(v-if="!showProjectCase && !(jurisdictionCode.includes(0) || jurisdictionCode.includes(1))") 需求方已设为暂不显示
  .project-interface-case-btns(v-if="jurisdictionCode.includes(0) || jurisdictionCode.includes(1)")
    .project-interface-case-btn(@click="show" v-if="!showBtn") 显
    .project-interface-case-btn(@click="hidden" v-if="showBtn") 密
    .project-interface-case-btn-add
      img(src="../../../assets/add-btn.png")
      input(type="file" :accept="accept" @change="handleFileChange")
</template>

<script>
import {getCaseList, showCase, caseSort, hideCase, isShowCase, delCase, addCase} from '../../../api/project.js'
import {FileUpload} from './../../../api/index'
// import commentDialog from './commentDialog.vue'
import draggable from 'vuedraggable'
export default {
  name: 'project-interface-case',
  components: {draggable},
  props: ['projectId', 'jurisdictionCode', 'status'],
  data() {
    return {
      caseList: [],
      showProjectCase: false,
      showBtn: false,
      accept: 'image/jpg,image/jpeg,image/gif,image/png'
    }
  },
  watch: {
    jurisdictionCode(val) {
      if (val && (val.includes(0) || val.includes(1))) {
        this.showProjectCase = true
        this.getList()
        isShowCase({
          projectId: this.projectId
        }).then(res => {
          this.showBtn = res.data
        })
      }
    },
    caseList(val, oldVal) {
      if (oldVal.length === 0) return
      console.log('[ val ]-51', val)
      const params = {
        projectId: this.projectId,
        ids: val.map(item => item.id)
      }
      caseSort(params)
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      isShowCase({
        projectId: this.projectId
      }).then(res => {
        this.showBtn = res.data
        this.showProjectCase = res.data
        this.getList()
      })
    },
    getList() {
      getCaseList({
        projectId: this.projectId
      }).then(res => {
        this.caseList = res.code === 200 ? res.data : []
      })
    },
    handleDeleteInterface(info) {
      delCase({
        projectId: this.projectId,
        id: info.id
      }).then(res => {
        if (res.code === 200) {
          this.getList()
          this.$message.success('删除成功')
        }
      })
    },
    show() {
      showCase({
        projectId: this.projectId
      }).then(res => {
        if (res.code === 200) {
          this.$message.success('设置显示成功')
          this.init()
        }
      })
    },
    openTab(url) {
      window.open(`${window.location.origin}${window.location.pathname}#/imgView?picUrl=${url}`)
    },
    hidden() {
      hideCase({
        projectId: this.projectId
      }).then(res => {
        if (res.code === 200) {
          this.$message.success('设置隐藏成功')
          this.init()
        }
      })
    },
    handleFileChange(e) {
      const files = e.target.files
      if (!files.length) return
      const file = files[0]
      const params = new FormData()
      params.append('picFile', file)
      FileUpload(params).then(res => {
        const params = {
          projectId: this.projectId,
          picUrl: res.data,
          smallPicUrl: res.data
        }
        addCase(params).then(res => {
          if (res.code === 200) {
            this.$message.success('添加成功')
            this.getList()
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.project-interface-case {
  background-color: #fff;
  border-radius: 8px;
  padding: 40px 80px 93px 80px;
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;

  .project-interface-case-title {
    height: 30px;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 30px;
    display: flex;
    justify-content: space-between;
  }
  .el-divider {
    height: 1px;
    margin: 30px 0 60px 0;
  }
  .notice {
    margin: 0 auto;
    text-align: center;
  }
  .project-interface-case-content {
    .project-interface-case-item {
      position: relative;
      display: inline-block;
      margin: 0 52px 0 52px;
      height: 563px;
      .project-interface-img {
        height: 500px;
        width: 250px;
        margin-bottom: 83px;
        cursor: pointer;
      }
      .project-interface-img-delete {
        height: 30px;
        width: 30px;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -15px;
        margin-bottom: 10px;
        cursor: pointer;
      }
    }
    .project-interface-case-item:nth-child(2n + 1) {
      margin-left: 41px;
    }
    .project-interface-case-item:nth-child(3n + 3) {
      margin-right: 41px;
    }
  }
  .project-interface-case-btns {
    display: flex;
    justify-content: flex-end;
    margin: 60px 2px 0 0;
    .project-interface-case-btn {
      cursor: pointer;
      height: 60px;
      width: 60px;
      font-size: 26px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 60px;
      border-radius: 50%;
      padding-left: 17px;
      background: #5ad3cd;
      margin-left: 30px;
    }
    .project-interface-case-btn-add {
      height: 60px;
      width: 60px;
      border-radius: 50%;
      cursor: pointer;
      padding: 15px;
      background: #5ad3cd;
      margin-left: 30px;
      margin-left: 30px;
      position: relative;
      cursor: pointer;
      img {
        height: 30px;
        width: 30px;
      }
      input {
        height: 60px;
        width: 60px;
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        cursor: pointer;
      }
    }
  }
}
</style>
