<template>
  <div class="set-standard">
    <dialogMask :show.sync="showDialog" :showClose="true">
      <div class="title">
        <img src="../../../../../public/icon/newStanard/项目设置.png" alt="" />
        <div class="title-desc">质检标准</div>
      </div>
      <div class="content">
        <el-form ref="form" label-position="left" :model="form" label-width="130px">
          <el-form-item label="质检方案">
            <el-select v-model="form.qualityPlan" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="form.qualityPlan == 2" label="预设质检包">
            <el-select v-model="form.preQualityPackage" placeholder="请选择预设质检包">
              <el-option
                v-for="item in preQualityList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                :disabled="item.disabled"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="footer">
        <el-button class="button-sure" @click="sureToSet" :disabled="form.qualityPlan == 2 && !form.preQualityPackage"
          >设置</el-button
        >
      </div>
    </dialogMask>
  </div>
</template>

<script>
import dialogMask from '@/components/common/dialog.vue'
import {getStandardSaveProjectPackagePresetApi, getStandardPackagePresetListApi} from '@/api/standard.js'

export default {
  name: 'ualityStandards',
  props: ['showQualityDialog', 'jurisdictionCode', 'projectId', 'standardPackageId', 'payedOrderId'],
  data() {
    return {
      showDialog: false,
      options: [
        {
          value: 1,
          label: '暂不需要'
        },
        {
          value: 2,
          label: '使用预设质检包'
          // disabled: true
        }
        // {
        //   value: 3,
        //   label: '自由设置',
        //   // disabled: true
        // },
      ],
      preQualityList: [
        {
          id: 1,
          label: '预设质检包'
        }
      ],
      form: {
        qualityPlan: 1,
        preQualityPackage: ''
      }
    }
  },
  watch: {
    showQualityDialog(val) {
      if (val) {
        this.showDialog = true
        if (this.standardPackageId) {
          this.form.qualityPlan = 2
          this.form.preQualityPackage = this.standardPackageId
        }
      } else {
        this.showDialog = false
      }
    },
    showDialog(val) {
      if (!val) {
        this.$emit('update:showQualityDialog', false)
      }
    },
    ['form.qualityPlan']() {
      if (this.form.qualityPlan == 1) {
        this.form.preQualityPackage = ''
      }
    }
  },
  components: {
    dialogMask
  },
  created() {
    if (this.standardPackageId) {
      this.form.qualityPlan = 2
      this.form.preQualityPackage = this.standardPackageId
    }
    getStandardPackagePresetListApi().then(res => {
      console.log('获取预检包....', JSON.stringify(res, null, 2))
      this.preQualityList = res.data
    })
  },
  methods: {
    sureToSet() {
      let confirmText = ''
      if (this.payedOrderId) {
        this.$alert()
        this.$alert('项目的质检标准订单已支付，不能再调整标准（如需调整，可先将订单退款）', {
          confirmButtonText: '确定',
          callback: () => {
            this.$emit('uploadSuccess')
          }
        })
        return
      }
      if (!this.standardPackageId || this.standardPackageId == this.form.preQualityPackage) {
        this.continueSet()
        return
      }
      if (this.standardPackageId && this.form.qualityPlan == 1) {
        confirmText = '确定不需要质检标准吗？'
      }
      if (
        this.standardPackageId &&
        this.form.qualityPlan == 2 &&
        this.form.preQualityPackage != this.standardPackageId
      ) {
        confirmText = '确定更换质检标准吗？'
      }

      this.$confirm(confirmText, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.continueSet()
        })
        .catch(() => {})
    },
    continueSet() {
      getStandardSaveProjectPackagePresetApi({
        projectId: this.projectId,
        packageId: this.form.preQualityPackage,
        setStatus: this.form.qualityPlan
      })
        .then(res => {
          console.log('设置预检包结果....', JSON.stringify(res, null, 2))
          // 关闭弹窗并刷新页面
          this.$emit('uploadSuccess')
        })
        .catch(err => {
          this.$emit('uploadSuccess')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.set-standard {
  user-select: none;

  .title {
    padding-left: 40px;
    padding-top: 20px;
    margin-bottom: 31px;

    img {
      width: 29px;
      height: 32px;
      margin-top: 2px;
      margin-right: 10px;
      display: inline-block;
      font-size: 0;
    }

    .title-desc {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 33px;
      display: inline-block;
      vertical-align: top;
    }
  }

  .content {
    margin-top: 46px;
    padding-left: 80px;

    /deep/ .el-form-item__label {
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 50px;
    }
  }

  .footer {
    text-align: center;
    margin-top: 60px;
  }

  .button-sure {
    width: 446px;
    height: 46px;
    background: #5ad3cd;
    border-radius: 23px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    margin: 0 auto;
  }
}
</style>
<style lang="scss">
.half-helf {
  width: 100px !important;

  .el-input {
    width: 100px;
  }
}

.right {
  margin-right: 20px;
}
</style>
