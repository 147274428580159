<template lang="pug">
.comp-info-box
  .comp-info-container
    .comp-info-point
    .comp-info-title 投标要求
  .comp-info-basic-container(v-for="(item, index) in compInfoms" :key="`${index}bidDemand`")
    .comp-info-basic {{item.label}}：
    .comp-info-basic-info {{item.value}}
  .comp-info-basic-container(v-for="(item, index) in bidDemandInfo" :key= "index")
    .comp-info-basic {{item.title}}
    .comp-info-basic-info {{item.value}}
  el-button.set(@click="handleSetBidDemand" type="primary" v-if="(jurisdictionCode.includes(0) || jurisdictionCode.includes(1)) && statusList[status] !== '已停止'") 设置
  bidRequire(
    :projectId="projectId",
    :info="info"
    :showSetDialog.sync="showSetDialog",
    @uploadSuccess="uploadSuccess",
  )
</template>

<script>
import {getProComInfo} from '@/api/project'
import bidRequire from '@/components/project/projectCompInfo/dialog/bidRequire.vue'
import {statusList} from '@/config/status.js'

export default {
  name: 'bidDemand',
  props: ['projectId', 'jurisdictionCode', 'status'],
  data() {
    return {
      bidDemandInfo: [],
      compInfoms: [],
      info: {},
      statusList,
      showSetDialog: false
    }
  },
  components: {
    bidRequire
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const params = {
        projectId: this.projectId
      }
      getProComInfo(params)
        .then(res => {
          if (res.code === 200) {
            const info = res.data
            const result = []
            let sampleManuscript = `${info.paymentPerDraft ? `每稿${info.paymentPerDraft}元报酬` : ''}`
            const provideName =
              info.demandProductDraft && info.demandDraftNecessary && info.demandDraft
                ? '必须提供'
                : !info.demandDraftNecessary && !info.demandDraft
                ? '无需提供'
                : '可选提供'
            sampleManuscript = `${provideName}“${info.draftPageName}”${
              info.demandProductDraft && info.demandUiDraft ? '产品及视觉' : info.demandProductDraft ? '产品' : '视觉'
            }样稿${sampleManuscript ? `，${sampleManuscript}` : `。`}`
            if (info.demandRealCase) {
              result.push({
                label: '案例',
                value: info.demandRealCase ? '案例真实，支持核验' : ''
              })
            }
            if (info.demandSourceCode) {
              result.push({
                label: '源码',
                value: info.demandSourceCode
                  ? `提供完整源文件，包括原型图、视觉设计、技术方案和源代码；${
                      info.demandCodeExplication ? '配备代码、数据库等录屏解说视频' : ''
                    }`
                  : ''
              })
            }
            if (info.demandDraft) {
              result.push({
                label: '样稿',
                value: sampleManuscript
              })
            }
            if (info.demandOthers) {
              result.push({
                label: '其他',
                value: info.demandOthers
              })
            }
            this.$set(this, 'info', info)
            this.$set(this, 'compInfoms', result)
          }
        })
        .catch(err => err)
    },
    handleSetBidDemand() {
      this.showSetDialog = true
    },
    uploadSuccess() {
      this.$emit('uploadSuccess')
    }
  }
}
</script>

<style lang="scss" scoped>
.comp-info-box {
  margin: 40px 0;
  .comp-info-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .comp-info-point {
      width: 9px;
      height: 9px;
      background: #5ad3cd;
      margin: 10px 11px 10px 0;
    }
    .comp-info-title {
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
  }
  .comp-info-basic-container {
    margin: 0 0 16px 0;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    display: flex;
    .comp-info-basic {
      color: #999999;
      margin-right: 10px;
      white-space: nowrap;
    }
    .comp-info-basic-info {
      color: #333333;
    }
  }
  button {
    background: #5ad3cd;
    padding: 3px 26px;
    border-radius: 15px;
  }
  .set {
    width: 80px;
    background: #5ad3cd;
    border-radius: 15px;
    margin-top: 20px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 26px;
    padding: 0;
  }
}
</style>
