<template lang="pug">
.project
  layoutContainer
    projectHead(
      :projectInfo="projectInfo",
      :projectId="projectId",
      :partList="partList"
      @setStatus="setStatus",
      :status="statusList[projectInfo.status]"
      :jurisdictionCode="jurisdictionCode"
      @publishSuccess="publishSuccess")
    projectWord(
      :projectId="projectId",
      :jurisdictionCode="jurisdictionCode"
      :status="statusList[projectInfo.status]"
      @updateWord="publishSuccess")
    projectCompInfo(
      :jurisdictionCode="jurisdictionCode"
      :status="projectInfo.status"
      :finalVersion="projectInfo.finalVersion"
      :projectId="projectId"
      @uploadAuth="uploadAuth"
    )
    project-plan(
      :projectId="projectId", 
      :jurisdictionCode="jurisdictionCode"
      :status="statusList[projectInfo.status]"
    )
    projectMember(
      :jurisdictionCode="jurisdictionCode"
      v-if="showProjectMember"
      :projectId="projectId"
      :status="statusList[projectInfo.status]"
    )
    projectProgress(
      :jurisdictionCode="jurisdictionCode"
      :status="statusList[projectInfo.status]"
      :projectId="projectId"
    )
    project-comment(
      :projectId="projectId", 
      :jurisdictionCode="jurisdictionCode"
      :status="projectInfo.status"
    )
    project-interface-case(
      :projectId="projectId", 
      :jurisdictionCode="jurisdictionCode"
      :status="projectInfo.status"
    )
</template>

<script>
import {
  getDescribeInfoByProjectId,
  getCurrentUserAuthByProjectId,
  updateStatus,
  projectEntrustPart
} from '@/api/project'
import projectHead from '@/components/project/projectInfo/index.vue'
import projectWord from '@/components/project/projectWord/index.vue'
import projectCompInfo from '@/components/project/projectCompInfo/index.vue'
import projectMember from '@/components/project/projectMember/index.vue'
import projectProgress from '@/components/project/projectProgress/index.vue'
import {statusList} from '@/config/status.js'
import projectPlan from '@/components/project/projectPlan/index.vue'
import projectComment from '@/components/project/projectComment/index.vue'
import projectInterfaceCase from '@/components/project/projectInterfaceCase/index.vue'

export default {
  name: 'ProjectDetail',
  data() {
    return {
      projectInfo: {
        status: 0
      },
      projectId: '',
      jurisdictionCode: '',
      partList: [],
      statusList,
      showProjectMember: true
    }
  },
  components: {
    projectWord,
    projectHead,
    projectCompInfo,
    projectMember,
    projectProgress,
    projectPlan,
    projectComment,
    projectInterfaceCase
  },
  created() {
    const {id: projectId} = this.$route.query
    this.projectId = projectId
    getCurrentUserAuthByProjectId({
      projectId
    }).then(res => {
      this.jurisdictionCode = res.data
    })
    projectEntrustPart().then(res => {
      this.partList = res.data
    })
    console.log(111)
    this.init()
  },
  methods: {
    init() {
      const {id: projectId} = this.$route.query
      getDescribeInfoByProjectId({
        projectId
      })
        .then(res => {
          const projectInfo = JSON.parse(JSON.stringify(res.data))
          // eslint-disable-next-line no-debugger
          this.projectInfo = this.$set(this, 'projectInfo', projectInfo)
          console.log('var12121221 ', projectInfo)
        })
        .catch(err => {
          console.log('err ', err)
        })
    },
    uploadAuth() {
      const {id: projectId} = this.$route.query
      getCurrentUserAuthByProjectId({
        projectId
      }).then(res => {
        this.jurisdictionCode = res.data
      })
      this.init()
    },
    setStatus(status) {
      const params = {
        projectId: this.projectId,
        status: status
      }
      updateStatus(params).then(res => {
        if (res.code === 200) {
          this.$message.success(`${status ? '开始成功' : '已停止'}`)
          this.init()
        }
      })
    },
    publishSuccess() {
      this.init()
    }
  }
}
</script>

<style lang="scss" scoped>
$radius: 8px;
$fontFamily: PingFangSC-Medium, PingFang SC;
$btnColor: #55cbc4;
.project {
  min-height: 100%;
  overflow: hidden;
}
</style>
