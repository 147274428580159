<template lang="pug">
.set-content
  dialogMask(:show.sync="showDialog", :showClose="true")
    .title
      img(src="../../../assets/progress.png" alt="")
      .title-desc 设置模块进度
    .content-list(v-if="progressList && progressList.length > 0")
      draggable(v-model="progressList")
        .list(v-for="item in progressList")
          .name {{item.name}}
          .progress {{item.percent}}%
          .del(@click="change(item)") 修改
          .del(@click="del(item)") 删除
    .content-add
      .options
        .name 模块名称
        .item
          el-input.common-width(v-model="form.name" placeholder="请输入内容")
      .options
        .name 进度
        .item
          el-select.half(v-model="form.percent" placeholder="请选择")
            el-option(label="0%" value="0")
            el-option(label="10%" value="10")
            el-option(label="20%" value="20")
            el-option(label="30%" value="30")
            el-option(label="40%" value="40")
            el-option(label="50%" value="50")
            el-option(label="60%" value="60")
            el-option(label="70%" value="70")
            el-option(label="80%" value="80")
            el-option(label="90%" value="90")
            el-option(label="100%" value="100")
    .add(@click="add") {{id ? '修改模块' : '增加模块'}}
</template>

<script>
import dialogMask from '@/components/common/dialog.vue'
import draggable from 'vuedraggable'
import {getModuleList, addModuleList, delModel, updateModuleList, updateModuleOrder} from '@/api/project'

export default {
  name: 'addModelProgress',
  props: ['showModelDialog', 'projectId', 'tendererId'],
  data() {
    return {
      showDialog: false,
      progressList: [],
      newList: [],
      form: {
        name: '',
        percent: '0'
      },
      id: ''
    }
  },
  watch: {
    showModelDialog(val) {
      if (val) this.showDialog = true
    },
    showDialog(val) {
      if (!val) {
        this.form = {
          name: '',
          percent: '0'
        }
        this.id = ''
        this.$emit('update:showModelDialog', false)
      } else {
        this.init()
      }
    },
    progressList(val, old) {
      if (old.length === 0) return
      const data = JSON.parse(JSON.stringify(val))
      const list = []
      data.forEach((item, index) => {
        list.push({
          id: item.id,
          sequenceNum: index + 1
        })
      })
      const params = {
        list,
        projectId: this.projectId
      }
      updateModuleOrder(params).then(res => {
        this.$emit('uploadSuccess')
      })
    }
  },
  components: {
    dialogMask,
    draggable
  },
  methods: {
    init() {
      const params = {
        projectId: this.projectId
      }
      console.log('params ', params)
      getModuleList(params)
        .then(res => {
          this.progressList = res.data || []
        })
        .catch(err => err)
    },
    change(info) {
      this.id = info.id
      this.form = {
        name: info.name,
        percent: info.percent
      }
    },
    add() {
      if (!this.form.name) {
        this.$message.error('请输入模块名称')
      }
      if (this.id) {
        updateModuleList(
          Object.assign(this.form, {
            projectId: this.projectId,
            id: this.id
          })
        ).then(res => {
          if (res.code === 200) {
            this.$message.success('更新成功')
            this.init()
            this.$emit('uploadSuccess')
          } else {
            this.$message.error(res.msg)
          }
          this.id = ''
          this.form = {
            name: '',
            percent: ''
          }
        })
      } else {
        addModuleList(
          Object.assign(this.form, {
            projectId: this.projectId
          })
        ).then(res => {
          if (res.code === 200) {
            this.$message.success('添加成功')
            this.init()
            this.$emit('uploadSuccess')
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    del(info) {
      delModel({
        projectId: this.projectId,
        id: info.id
      }).then(res => {
        if (res.code === 200) {
          this.init()
          this.$message.success('删除成功')
          this.$emit('uploadSuccess')
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.set-content {
  user-select: none;
  .title {
    padding-left: 20px;
    margin-bottom: 40px;
    img {
      width: 34px;
      height: 34px;
      margin-right: 10px;
      display: inline-block;
      font-size: 0;
    }
    .title-desc {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 34px;
      display: inline-block;
      vertical-align: top;
    }
  }
  .add {
    width: 446px;
    height: 46px;
    background: #5ad3cd;
    border-radius: 23px;
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
    margin: 0 auto;
    cursor: pointer;
  }
  .options {
    margin-bottom: 20px;
    position: relative;
    .name {
      width: 120px;
      height: 50px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 50px;
      text-align: left;
      position: absolute;
      left: 0;
      top: 0;
    }
    .item {
      height: 50px;
      line-height: 50px;
      margin-left: 110px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      position: relative;
    }
  }
  .content-list {
    margin: 0 auto 60px;
    border-bottom: 1px solid #dedede;
    overflow: hidden;
    .list {
      height: 34px;
      overflow: hidden;
      margin-bottom: 30px;
      text-align: center;
      .name {
        height: 34px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #2689fd;
        line-height: 34px;
        display: inline-block;
        margin-right: 86px;
        vertical-align: top;
        width: 120px;
      }
      .progress {
        width: 60px;
        height: 34px;
        margin-right: 84px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 34px;
        display: inline-block;
        vertical-align: top;
      }
      .del {
        vertical-align: top;
        display: inline-block;
        width: 80px;
        height: 34px;
        background: #ff724c;
        border-radius: 4px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 34px;
        text-align: center;
        margin-right: 20px;
        cursor: pointer;
      }
    }
  }
  .content-add {
    margin-left: 130px;
    .options {
      margin-bottom: 20px;
      span {
        margin-left: 10px;
        vertical-align: top;
        display: inline-block;
      }
    }
    .common-width {
      width: 200px;
      margin-right: 30px;
    }
  }
  .submit {
    background-color: rgb(133, 253, 247);
    width: 446px;
    height: 46px;
    background: #5ad3cd;
    border-radius: 23px;
    margin: 60px auto 0;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
    text-align: center;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.common-width .el-input {
  width: 220px;
}
</style>
