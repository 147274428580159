<template lang="pug">
.set-content
  dialogMask(:show.sync="showDialog", :showClose="true")
    .title
      img(src="../../../assets/progress.png" alt="")
      .title-desc 新增个人进度
    .content-add
      .options
        .name 进度描述
        .item
          el-input.common-width.progress(
            v-model="form.description", 
            type="textarea" 
            placeholder="请输入内容"
          )
      .options(v-if="jurisdictionCode.includes(1)")
        .name 成员昵称
        .item
          el-input.common-width(
            v-model="form.nickName", 
            placeholder="请输入内容"
          )
    .add(@click="add") 新增
</template>

<script>
import dialogMask from '@/components/common/dialog.vue'
import {addMemberProcess, getModuleList} from '@/api/project'

export default {
  name: 'addModelProgress',
  props: ['showMemberDialog', 'projectId', 'jurisdictionCode'],
  data() {
    return {
      showDialog: false,
      progressList: [],
      form: {
        nickName: '',
        description: ''
      }
    }
  },
  watch: {
    showMemberDialog(val) {
      if (val) this.showDialog = true
    },
    showDialog(val) {
      if (!val) {
        this.$emit('update:showMemberDialog', false)
      } else {
        this.init()
      }
    }
  },
  components: {
    dialogMask
  },
  methods: {
    add() {
      addMemberProcess(
        Object.assign(this.form, {
          projectId: this.projectId
        })
      ).then(res => {
        if (res.code === 200) {
          this.form = {
            nickName: '',
            description: ''
          }
          this.$message.success('添加成功')
          this.showDialog = false
          this.$emit('uploadSuccess')
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.set-content {
  user-select: none;
  .title {
    padding-left: 20px;
    margin-bottom: 40px;
    img {
      width: 34px;
      height: 34px;
      margin-right: 10px;
      display: inline-block;
      font-size: 0;
    }
    .title-desc {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 34px;
      display: inline-block;
      vertical-align: top;
    }
  }
  .add {
    width: 446px;
    height: 46px;
    line-height: 46px;
    background: #5ad3cd;
    border-radius: 23px;
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
    margin: 0 0 0 200px;
  }
  .options {
    margin-bottom: 20px;
    position: relative;
    .name {
      width: 120px;
      height: 50px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 50px;
      text-align: left;
      position: absolute;
      left: 0;
      top: 0;
    }
    .item {
      line-height: 50px;
      margin-left: 110px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      position: relative;
    }
  }
  .content-add {
    margin-left: 100px;
    .options {
      margin-bottom: 60px;
      span {
        margin-left: 10px;
        vertical-align: top;
        display: inline-block;
      }
    }
    .common-width {
      width: 500px;
      border-radius: 4px;
    }
  }
}
</style>
<style lang="scss">
.progress .el-textarea__inner {
  width: 500px;
  height: 200px;
  border-radius: 4px;
}
</style>
