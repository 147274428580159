/* eslint-disable no-debugger */
<template lang="pug">
.project-member
  .member-box
    h4.title(clang="member-box-title") 项目进度
    el-divider.el-divider
    .sub_title
      span
      p 模块进度
      img(
        v-if="status !== '已停止' && (jurisdictionCode.includes(0) || jurisdictionCode.includes(1) || jurisdictionCode.includes(8) || jurisdictionCode.includes(9) || (jurisdictionCode.includes(7) || jurisdictionCode.includes(2)))"
        src="./../../../assets/bianji-2@2x.png"
        @click="addModelProgress"
      )
    .content
      .progress(v-for="item in progressList")
        .name {{item.name}}{{item.percent}}%
        .progress-line
          .origin
          .practical(:style="{width: `${item.percent}%`}")
    .sub_title
      span
      p 项目进度
      img(
        v-if="status !== '已停止' && (jurisdictionCode.includes(0) || jurisdictionCode.includes(1) || jurisdictionCode.includes(8) || jurisdictionCode.includes(9) || (jurisdictionCode.includes(7) || jurisdictionCode.includes(2)))"
        src="./../../../assets/jiahao2fill@2x.png"
        @click="addProgress"
      )
    .content
      .project-progress(v-for="(item, index) in projectProgressList", v-show="index < 3")
        img.logo-pic(:src="item.logoPicUrl" @click="handelToDeatil(item)")
        .progerss-desc(@click="handelToDeatil(item)")
          .name 
            span.org-name {{item.orgName}}
            span.submitter-type {{item.submitterType}}
            span.time {{item.createTime}}
          .desc {{item.description}}
        img.del(@click="delProcess(item.id)" src="./../../../assets/delete.png" v-if="status !== '已停止' && (jurisdictionCode.includes(1) || item.hasDeleteAuth)")
    .check-all(v-show="projectProgressList.length > 3")
      .btn(@click="showProjectProgressAll = true") 查看全部..
    .sub_title
      span
      p 个人进度
      img(
        v-if="status !== '已停止' && (jurisdictionCode.includes(0) || jurisdictionCode.includes(1) || jurisdictionCode.includes(6))"
        src="./../../../assets/jiahao2fill@2x.png"
        @click="showMemberDialog = true"
      )
    .content
      .project-progress(v-for="(item, index) in memberProjressList", v-show="index < 3")
        img.author-pic(:src="item.picUrl" @click="gotoCompanyDetailPage(item.employeeId)")
        .progerss-desc
          .name 
            span.nick-name(@click="gotoCompanyDetailPage(item.employeeId)") {{item.nickName}}
            span.add-time {{item.createTime}}
            .member-desc {{item.description}}
        img.member-del(src="./../../../assets/delete.png" v-if="status !== '已停止' && (jurisdictionCode.includes(1) || item.hasDeleteAuth)" @click="delMemberProgress(item.id)")
    .check-all(v-show="memberProjressList.length > 3")
      .btn(@click="showMemberProgressAll = true") 查看全部..
  addModelProgress(
    :showModelDialog.sync="showModelDialog" 
    :projectId="projectId" 
    @uploadSuccess="uploadSuccess"
  )
  addProjectProgress(
    :showProjectDialog.sync="showProjectDialog" 
    :projectId="projectId" 
    :jurisdictionCode="jurisdictionCode"
    @uploadSuccess="uploadProjectSuccess"
  )
  addMemberProgress(
    :showMemberDialog.sync="showMemberDialog" 
    :projectId="projectId" 
    :jurisdictionCode="jurisdictionCode"
    @uploadSuccess="updateProcessMemberList"
  )
  memberProgressAll(
    :list="memberProjressList"
    :projectId="projectId"
    :status="status"
    :jurisdictionCode="jurisdictionCode"
    :showMemberProgressAll.sync="showMemberProgressAll",
    @updateProcessMemberList="updateProcessMemberList"
  )
  projectProgressAll(
    :list="projectProgressList"
    :status="status"
    :projectId="projectId" 
    @updateProgress="uploadProjectSuccess"
    :showProjectProgressAll.sync="showProjectProgressAll",
  )
</template>

<script>
import {getModuleList, getProcessList, delMemberProgress, getProcessMemberList, delProcess} from '@/api/project'
import addModelProgress from './addModelProgress'
import addProjectProgress from './addProjectProgress'
import addMemberProgress from './addMemberProgress'
import memberProgressAll from './memberProgressAll'
import projectProgressAll from './projectProgressAll'

export default {
  name: 'compInfo',
  props: ['projectId', 'jurisdictionCode', 'status'],
  data() {
    return {
      progressList: [],
      projectProgressList: [],
      memberProjressList: [],
      showModelDialog: false,
      showProjectDialog: false,
      showMemberDialog: false,
      showProjectProgressAll: false,
      showMemberProgressAll: false
    }
  },
  components: {
    addModelProgress,
    addProjectProgress,
    addMemberProgress,
    projectProgressAll,
    memberProgressAll
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const params = {
        projectId: this.projectId
      }
      console.log('params ', params)
      getModuleList(params)
        .then(res => {
          this.progressList = res.data
        })
        .catch(err => err)
      this.uploadProjectSuccess()
      this.updateProcessMemberList()
    },
    set() {
      this.showDialog = true
    },
    addModelProgress() {
      this.showModelDialog = true
    },
    delProcess(id) {
      delProcess({
        projectId: this.projectId,
        id: id
      }).then(res => {
        if (res.code === 200) {
          this.$message.success('删除成功')
          this.uploadProjectSuccess()
        } else {
          this.$message.error(res.msg)
        }
      })
      console.log('id ', id)
    },
    uploadSuccess() {
      this.init()
    },
    addProgress() {
      this.showProjectDialog = true
    },
    gotoCompanyDetailPage(id) {
      this.$router.push({
        path: `/personal/detail/${id}`
      })
    },
    updateProcessMemberList() {
      const params = {
        projectId: this.projectId
      }
      getProcessMemberList(params).then(res => {
        this.memberProjressList = res.data || []
      })
    },
    handelToDeatil(item) {
      if (item.submitterType === '公司') {
        this.$router.push({
          path: `/companyDetail/${item.orgId}`
        })
      } else {
        this.$router.push({
          path: `/teams/detail/${item.orgId}`
        })
      }
    },
    delMemberProgress(id) {
      delMemberProgress({
        projectId: this.projectId,
        id: id
      }).then(res => {
        if (res.code === 200) {
          this.$message.success('删除成功')
          this.updateProcessMemberList()
        } else {
          this.$message.error(res.msg)
        }
      })
      console.log('id ', id)
    },
    uploadProjectSuccess() {
      const params = {
        projectId: this.projectId
      }
      getProcessList(params).then(res => {
        this.projectProgressList = res.data || []
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.project-member {
  background: #ffffff;
  border-radius: 8px;
  padding: 25px 80px 0px 80px;
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
  .content {
    overflow: hidden;
  }
  .check-all {
    height: 24px;
    margin-bottom: 30px;
    .btn {
      width: 80px;
      height: 24px;
      background: #f1f1f1;
      border-radius: 2px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #4a90e2;
      text-align: center;
      line-height: 24px;
      float: right;
      cursor: pointer;
    }
  }
  .project-progress {
    overflow: hidden;
    margin-bottom: 46px;
    .author-pic {
      width: 46px;
      height: 46px;
      float: left;
      border-radius: 50%;
      margin-right: 25px;
    }
    .logo-pic {
      width: 40px;
      height: 30px;
      border-radius: 2px;
      float: left;
      margin-right: 20px;
      cursor: pointer;
    }
    .progerss-desc {
      width: 922px;
      float: left;
      cursor: pointer;
      .name {
        width: 922px;
        margin-bottom: 20px;
        .nick-name {
          width: 36px;
          height: 25px;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 25px;
          margin-right: 30px;
          vertical-align: top;
        }
        .add-time {
          width: 160px;
          height: 26px;
          background: #f6f6f6;
          border-radius: 2px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 26px;
          text-align: center;
          margin-right: 40px;
          vertical-align: top;
        }
        .org-name {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 22px;
          display: inline-block;
          margin-right: 5px;
        }
        .submitter-type {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          margin-right: 63px;
        }
        .time {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 22px;
        }
        .member-desc {
          width: 420px;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 26px;
          display: inline-block;
        }
        .desc {
          width: 922px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 22px;
        }
      }
    }
    .del,
    .member-del {
      float: right;
      width: 20px;
      height: 20px;
      margin-top: 20px;
      cursor: pointer;
    }
    .member-del {
      margin-top: 10px;
    }
  }
  .title {
    height: 30px;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 30px;
  }
  .el-divider {
    margin: 30px 0 30px 0;
  }
  h4 {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  .el-timeline-item__node--normal {
    top: 5px;
  }
  .member-item-box {
    cursor: pointer;
  }
  .add {
    width: 80px;
    height: 40px;
    background: #5ad3cd;
    border-radius: 2px;
    text-align: center;
    float: right;
    font-size: 16px;
    line-height: 40px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 80px;
  }
  .sub_title {
    margin-bottom: 37px;
    span {
      display: inline-block;
      width: 3px;
      height: 24px;
      background: #5ad3cd;
      margin-right: 10px;
      margin-top: 3px;
      vertical-align: top;
    }
    p {
      display: inline-block;
      margin-right: 10px;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 30px;
      vertical-align: top;
    }
    img {
      display: inline-block;
      margin-top: 5px;
      vertical-align: top;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
  .progress {
    margin-right: 56px;
    margin-bottom: 35px;
    display: inline-block;
    &:nth-child(4n) {
      margin-right: 0px;
    }
    .name {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
      width: 110px;
      margin-right: 26px;
      display: inline-block;
    }
    .progress-line {
      display: inline-block;
      margin-top: 5px;
      width: 120px;
      height: 12px;
      position: relative;
      .origin {
        width: 120px;
        height: 12px;
        background: #f1f1f1;
      }
      .practical {
        position: absolute;
        height: 12px;
        top: 0;
        left: 0;
        background: rgba(90, 211, 205, 1);
      }
    }
  }
}
</style>
