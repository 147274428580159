/* eslint-disable prettier/prettier */
<template lang="pug">
.project-plan
  .project-plan-title
    p.name 项目计划
    el-button.btn(@click="handleSetProjectPlan" v-if="status !== '已停止' && (jurisdictionCode.includes(0) || jurisdictionCode.includes(1) || jurisdictionCode.includes(9) || jurisdictionCode.includes(8) || (jurisdictionCode.includes(2) && (jurisdictionCode.includes(4) || jurisdictionCode.includes(5)  || jurisdictionCode.includes(7))))") 设置
  el-divider.el-divider
  .project-plan-content(v-if="projectPlanList.length !== 0")
    .project-complete-name
      p 计划完成
      p 实际完成
    .content
      .project-complete-plan(:style="{width: `${projectPlanList.length * 80}px`}")
        .project-complete-plan-axis(v-for="(item, index) in projectPlanList" :key="item.id")
          .project-complete-plan-axis-line
          .project-complete-plan-axis-node
            .project-complete-plan-axis-node-circle(:class="{'selected': item.status === '进行中', 'not-begin' : item.status === '未开始', 'complete' : item.status === '已完成'}") {{index + 1}}
            .project-complete-plan-axis-node-status(:class="{'selected-name': item.status === '进行中'}") {{item.name}}
            .project-complete-plan-time-plan {{item.plannedFinishDate ? item.plannedFinishDate : ' '}}
            .project-complete-plan-time-actual {{item.actualFinishDate ?item.actualFinishDate : ' '}}
  set-project-plan-dialog(
    :projectId="projectId",
    :showSetProjectDialog.sync="showDialog"
  )

</template>

<script>
import {getProjectPlanList} from '../../../api/project.js'
import setProjectPlanDialog from './setProjectPlanDialog.vue'
export default {
  name: 'project-plan',
  components: {setProjectPlanDialog},
  props: ['projectId', 'jurisdictionCode', 'status'],
  data() {
    return {
      projectPlanList: [],
      showDialog: false
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const params = {
        projectId: this.projectId
      }
      getProjectPlanList(params).then(res => {
        console.log('res ', res)
        const data = res.data || []
        data.forEach(item => {
          item.actualFinishDate = item.actualFinishDate ? this.formatDate(item.actualFinishDate) : ''
          item.plannedFinishDate = item.plannedFinishDate ? this.formatDate(item.plannedFinishDate) : ''
        })
        this.projectPlanList = data
        console.log('this.projectPlanList ', this.projectPlanList)
      })
    },
    handleSetProjectPlan() {
      this.showDialog = true
    },
    formatDate(time) {
      const result = new Date(time.replace(/-/g, '/'))
      const month = result.getMonth() + 1
      const day = result.getDate() > 9 ? result.getDate() : `0${result.getDate()}`
      return `${month}.${day}`
    }
  }
}
</script>

<style lang="scss" scoped>
.project-plan {
  background-color: #fff;
  border-radius: 8px;
  padding: 25px 80px 54px 80px;
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;
  .project-plan-title {
    height: 30px;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 30px;
    display: flex;
    justify-content: space-between;

    .btn {
      width: 80px;
      height: 40px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      background: #5ad3cd;
    }
  }
  .el-divider {
    height: 1px;
    margin: 24px 0 40px 0;
  }
  .project-plan-content {
    position: relative;
    .project-complete-name {
      display: block;
      position: absolute;
      width: 72px;
      top: 122px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-right: 20px;

      p:nth-child(1) {
        margin-bottom: 20px;
      }
    }
    .content {
      margin-left: 92px;
      overflow-x: scroll;
    }
    .content::-webkit-scrollbar {
      display: none;
    }
    .project-complete-plan {
      // width: calc(100% - );
      display: inline-block;
      vertical-align: top;
      .project-complete-plan-axis:first-child .project-complete-plan-axis-line {
        display: none;
      }
      .project-complete-plan-axis {
        display: inline-block;
        vertical-align: top;
        .project-complete-plan-axis-line {
          width: 40px;
          height: 1px;
          background-color: red;
          display: inline-block;
          vertical-align: top;
          margin-top: 13px;
        }

        .project-complete-plan-axis-node {
          display: inline-block;
          position: relative;
          .project-complete-plan-axis-node-circle {
            border-radius: 50%;
            width: 26px;
            height: 26px;
            background: #333333;
            color: #ffffff;
            position: relative;
            margin: 0 6.5px;
            text-align: center;
            line-height: 26px;
            font-size: 13px;
          }
          .selected {
            background-color: #5ad3cd;
            color: #ffffff;
          }
          .complete {
            background-color: #333333;
            color: #ffffff;
          }
          .not-begin {
            background: #f1f1f1;
            color: #333333;
          }

          .project-complete-plan-axis-node-status {
            margin-top: 20px;
            width: 39px;
            font-size: 13px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 18px;
            text-align: center;
            position: absolute;
            word-break: break-all;
          }
          .selected-name {
            color: #5ad3cd !important;
          }
          .project-complete-plan-time-plan {
            margin-top: 98px;
            width: 27px;
            height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            // line-height: 18px;
          }
          .project-complete-plan-time-actual {
            margin-top: 27px;
            // width: 27px;
            // height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            // line-height: 18px;
          }
        }
      }
    }
  }
}
</style>
