<template lang="pug">
.set-content
  dialogMask(:show.sync="showDialog", :showClose="true")
    .title
      img(src="../../../../assets/项目成员@2x.png" alt="")
      .title-desc 项目成员
    .content-list(v-if="info && info.length > 0")
      ul
        li(v-for="(item, index) in info" :key="`${index}member`")
          span.name(@click="checkPeople(item)") {{item.nickName}}
          span.occupation {{item.occupationName}}
          span.years {{item.workYears}} 年
          span.project-director(v-show="item.projectDirector") 项目主管
          span.project-director.none(v-show="!item.projectDirector")
          span.quotation(:title="resetBudget(item.quotation)") {{resetBudget(item.quotation)}}
          span.workload(:title="`${item.workload}${item.workloadUnit === 1 ? '人天' : '小时'}(量)`") {{item.workload}}{{item.workloadUnit === 1 ? '人天' : '小时'}}(量)
          span.time {{item.durationMonth * 30 + item.durationDay}}天(期)
          img(src="./../../../../assets/delete.png" @click="del(item)" v-if="!showAction")
    .content-add(v-show="!showAction")
      .options
        .name 成员昵称
        .item
          el-input.common-width(v-model="form.nickName" placeholder="请输入内容")
      .options
        .name 报价
        .item
          el-input.common-width(v-model="form.quotation" placeholder="请输入内容")
          el-select.common-width(v-model="form.quotationUnit" placeholder="请选择")
              el-option(label="元" value="元")
              el-option(label="万" value="万")
      .options
        .name 工作量
        .item
          el-input.common-width(v-model="form.workload" placeholder="请输入内容")
          el-select.common-width(v-model="form.workloadUnit" placeholder="请选择")
            el-option(label="人天" value="1")
            el-option(label="小时" value="2")
      .options
        .name 工期
        .item
          el-select.budget-unit.common-width(v-model="form.durationDay" placeholder="请选择")
            el-option(label="0个月" value="0")
            el-option(label="1个月" value="1")
            el-option(label="2个月" value="2")
            el-option(label="3个月" value="3")
            el-option(label="4个月" value="4")
            el-option(label="5个月" value="5")
            el-option(label="6个月" value="6")
            el-option(label="7个月" value="7")
            el-option(label="8个月" value="8")
            el-option(label="9个月" value="9")
            el-option(label="10个月" value="10")
            el-option(label="11个月" value="11")
            el-option(label="12个月" value="12")
          el-select.budget-unit.common-width(v-model="form.durationMonth" placeholder="请选择")
            el-option(label="0天" value="0")
            el-option(label="1天" value="1")
            el-option(label="2天" value="2")
            el-option(label="3天" value="3")
            el-option(label="4天" value="4")
            el-option(label="5天" value="5")
            el-option(label="6天" value="6")
            el-option(label="7天" value="7")
            el-option(label="8天" value="8")
            el-option(label="9天" value="9")
            el-option(label="10天" value="10")
            el-option(label="11天" value="11")
            el-option(label="12天" value="12")
            el-option(label="13天" value="13")
            el-option(label="14天" value="14")
            el-option(label="14天" value="14")
            el-option(label="15天" value="15")
            el-option(label="16天" value="16")
            el-option(label="17天" value="17")
            el-option(label="18天" value="18")
            el-option(label="19天" value="19")
            el-option(label="20天" value="20")
            el-option(label="21天" value="21")
            el-option(label="22天" value="22")
            el-option(label="23天" value="23")
            el-option(label="24天" value="24")
            el-option(label="25天" value="25")
            el-option(label="26天" value="26")
            el-option(label="27天" value="27")
            el-option(label="28天" value="28")
            el-option(label="29天" value="29")
            el-option(label="30天" value="30")
      .options
        .name 项目主管
        .item
          el-switch.switch(
            v-model="form.projectDirector"
          )
          span {{form.projectDirector ? '是' : '否'}}
    .add(@click="add" v-if="!showAction") 新增成员
</template>

<script>
import dialogMask from '@/components/common/dialog.vue'
import {addMember, delTendererList} from '@/api/project'

export default {
  name: 'apply',
  props: ['showMemberDialog', 'projectId', 'info', 'tendererId', 'showAction'],
  data() {
    return {
      showDialog: false,
      form: {
        durationDay: '0',
        durationMonth: '0',
        nickName: '',
        projectDirector: false,
        quotation: 0,
        quotationUnit: '元',
        tenderId: 0,
        workload: 0,
        workloadUnit: '1'
      }
    }
  },
  watch: {
    showMemberDialog(val) {
      if (val) this.showDialog = true
    },
    showDialog(val) {
      if (!val) {
        this.$emit('update:showMemberDialog', false)
      }
    }
  },
  components: {
    dialogMask
  },
  methods: {
    del(info) {
      delTendererList({projectId: this.projectId, id: info.id}).then(res => {
        if (res.code === 200) {
          this.$parent.handleCheckPeople(this.tendererId, true)
          this.$message.success('删除成功')
          this.form = {
            durationDay: 0,
            durationMonth: 0,
            nickName: '',
            projectDirector: false,
            quotation: 0,
            quotationUnit: '元',
            tenderId: 0,
            workload: 0,
            workloadUnit: '1'
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    resetBudget(budget) {
      if (budget === '') return '未知'
      if (budget < 10000) return `${budget}元`
      return `${budget / 10000}万`
    },
    checkPeople(info) {
      this.$router.push({
        path: `/personal/detail/${info.employeeId}`
      })
    },
    add() {
      if (isNaN(this.form.quotation)) {
        this.$message.error('报价需为正整数')
        return
      }
      if (this.form.quotation < 0) {
        this.$message.error('报价需为正整数')
        return
      }
      if (isNaN(this.form.workload)) {
        this.$message.error('工作量需为正整数')
        return
      }
      if (this.form.workload < 0) {
        this.$message.error('工作量需为正整数')
        return
      }
      if (Number(this.form.workload) > 100000000) {
        this.$message.error('工作量需小于1亿')
        return
      }
      addMember(
        Object.assign(this.form, {
          projectId: this.projectId,
          tenderId: this.tendererId
        })
      ).then(res => {
        if (res.code === 200) {
          this.$parent.handleCheckPeople(this.tendererId, true)
          this.$message.success('添加成功')
          this.form = {
            durationDay: 0,
            durationMonth: 0,
            nickName: '',
            projectDirector: false,
            quotation: 0,
            quotationUnit: '',
            tenderId: 0,
            workload: 0,
            workloadUnit: '1'
          }
          this.$emit('uploadSuccess')
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.set-content {
  user-select: none;
  .title {
    padding-left: 20px;
    margin-bottom: 40px;
    img {
      width: 32px;
      margin-top: 2px;
      margin-right: 10px;
      display: inline-block;
      font-size: 0;
    }
    .title-desc {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 33px;
      display: inline-block;
      vertical-align: top;
    }
  }
  .add {
    width: 446px;
    height: 46px;
    background: #5ad3cd;
    border-radius: 23px;
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
    margin: 0 auto;
  }
  .occupation {
    width: 108px;
  }
  .options {
    margin-bottom: 20px;
    position: relative;
    .name {
      width: 120px;
      height: 50px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 50px;
      text-align: left;
      position: absolute;
      left: 0;
      top: 0;
    }
    .item {
      height: 50px;
      line-height: 50px;
      margin-left: 110px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      position: relative;
    }
  }
  .content-list {
    margin: 0 auto 60px;
    border-bottom: 1px solid #dedede;
    overflow: hidden;
    li {
      height: 30px;
      overflow: hidden;
      margin-bottom: 33px;
      span {
        height: 30px;
        font-size: 22px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 30px;
        padding-right: 20px;
        display: inline-block;
        vertical-align: top;
      }
      .name {
        width: 120px;
        padding-left: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
      }
      .years {
        width: 80px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .quotation {
        width: 110px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .workload {
        width: 110px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .time {
        width: 110px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .project-director {
        width: 90px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 30px;
        background: #5ad3cd;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 30px;
        text-align: center;
        vertical-align: top;
        padding: 0;
        margin-right: 30px;
      }
      .none {
        background: #fff;
      }
      img {
        vertical-align: top;
        margin: 5px 0 0 0;
        width: 20px;
        height: 20px;
      }
    }
  }
  .content-add {
    margin-left: 130px;
    .options {
      margin-bottom: 20px;
      span {
        margin-left: 10px;
        vertical-align: top;
        display: inline-block;
      }
    }
    .common-width {
      width: 200px;
      margin-right: 30px;
      /deep/ .el-input {
        width: 200px;
      }
    }
    /deep/ .el-input__inner {
      width: 200px;
    }
  }
  .submit {
    background-color: rgb(133, 253, 247);
    width: 446px;
    height: 46px;
    background: #5ad3cd;
    border-radius: 23px;
    margin: 60px auto 0;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 46px;
    text-align: center;
    cursor: pointer;
  }
}
</style>
<style lang="scss"></style>
